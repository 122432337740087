import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import emailjs from 'emailjs-com';
import CountUp from 'react-countup';

import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap-icons.css';
import '../assets/css/all.min.css';
import '../assets/css/fontawesome.min.css';
import '../assets/css/swiper-bundle.min.css';
import '../assets/css/animate.min.css';
import '../assets/css/jquery.fancybox.min.css';
import '../assets/css/boxicons.min.css';
import '../assets/css/preloader.css';
import '../assets/css/style2.css';

import logo from '../assets/img/logo.webp';
import home6BannerImg from '../assets/img/home-6/home6-banner-img.png';
import home6BannerImg2 from '../assets/img/home-6/home6-banner-img2.png';
import home6BannerImg3 from '../assets/img/home-6/home6-banner-img3.png';
import googleImg from '../assets/img/home-5/google-1.png';
import chooseImg1 from '../assets/img/home-6/choose-img-1.png';
import chooseVecTopR from '../assets/img/home-6/choose-vec-top-r.png';
import chooseVecBtmL from '../assets/img/home-6/choose-vec-btm-l.png';
import customWebImg from '../assets/pics/custom-web.jpg';
import mobileViewImg from '../assets/pics/mobileview.jpg';
import ecommerceImg from '../assets/pics/ecommerce.jpg';
import logoImg from '../assets/pics/logoimg.jpg';
import appDevImg from '../assets/pics/appdevimg.jpg';
import printDesignImg from '../assets/pics/printdesign.jpg';
import animationImg from '../assets/pics/animation.jpg';
import testimonialReviewImg from '../assets/img/home-6/home6-testimonoal-review.png';

import logo1Animal from '../assets/logo-design-slider/animal/logo1.jpg';
import logo2Animal from '../assets/logo-design-slider/animal/logo2.jpg';
import logo3Animal from '../assets/logo-design-slider/animal/logo3.jpg';
import logo4Animal from '../assets/logo-design-slider/animal/logo4.jpg';
import logo1Education from '../assets/logo-design-slider/education/logo1.jpg';
import logo2Education from '../assets/logo-design-slider/education/logo2.jpg';
import logo3Education from '../assets/logo-design-slider/education/logo3.jpg';
import logo4Education from '../assets/logo-design-slider/education/logo4.jpg';
import logo1Entertainment from '../assets/logo-design-slider/entertainment/logo1.jpg';
import logo2Entertainment from '../assets/logo-design-slider/entertainment/logo2.jpg';
import logo3Entertainment from '../assets/logo-design-slider/entertainment/logo3.jpg';
import logo4Entertainment from '../assets/logo-design-slider/entertainment/logo4.jpg';
import logo1Finance from '../assets/logo-design-slider/finance/logo1.jpg';
import logo2Finance from '../assets/logo-design-slider/finance/logo2.jpg';
import logo3Finance from '../assets/logo-design-slider/finance/logo3.jpg';
import logo4Finance from '../assets/logo-design-slider/finance/logo4.jpg';
import logo1HealthCare from '../assets/logo-design-slider/health-care/logo1.jpg';
import logo2Food from '../assets/logo-design-slider/food/logo2.jpg';
import logo3Food from '../assets/logo-design-slider/food/logo3.jpg';
import logo4Food from '../assets/logo-design-slider/food/logo4.jpg';
import logo1Marketing from '../assets/logo-design-slider/marketing/logo1.jpg';
import trustpilotImg from '../assets/img/home-5/trustpilot-1.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
// import SwiperCore from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/a11y';
import WOW from 'wowjs';
import 'animate.css';


const Home = () => {

    console.log(Autoplay);
    // SwiperCore.use([Autoplay, Pagination]);


    const bannerImages = [home6BannerImg, home6BannerImg2, home6BannerImg3];

    const services = [
        {
            img: customWebImg,
            title: 'Web Design & Development',
            description: 'Our Web Design & Development service is your gateway to a captivating online presence. We blend creativity with technical expertise to craft websites that engage, inspire, and convert. We deliver web solutions tailored to your unique needs, ensuring your digital footprint leaves a lasting impress.',
            link: 'service-details-web',
            index: '01'
        },
        {
            img: mobileViewImg,
            title: 'Mobile Website',
            description: 'Our Mobile Website service is designed to put your business at everyone\'s fingertips. We specialize in creating responsive, user-friendly websites optimized for mobile devices. With the help of our experience, your website will automatically adjust to various screen sizes and deliver a top-notch mobile and tablet user experience. Stay ahead in the mobile era with our tailored solutions.',
            link: 'service-details-Mobile',
            index: '02'
        },
        {
            img: ecommerceImg,
            title: 'E-Commerce',
            description: 'Our Ecommerce service is your ticket to a thriving online business. We specialize in creating secure, user-friendly, and visually appealing e-commerce platforms that increase revenue and client satisfaction. From robust product catalogs to seamless payment solutions, we empower you to expand your digital storefront and tap into the vast world of online retail. Helping you in expanding and succeeding in the digital market.',
            link: 'service-details-ECommerce',
            index: '03'
        },
        {
            img: logoImg,
            title: 'Logo Design',
            description: 'Our Logo Design service is the key to forging a memorable brand identity. We specialize in creating unique and distinctive logos that visually represent your business\'s essence. With a keen eye for aesthetics and a deep understanding of your brand\'s values, we create logos that leave a lasting impression and set you apart in a competitive market. Elevate your brand with our exceptional logo design expertise.',
            link: 'service-details-logo',
            index: '04'
        },
        {
            img: appDevImg,
            title: 'App Development',
            description: 'Our App Development service is your gateway to innovation and digital transformation. We specialize in creating custom mobile and web applications that address your unique business needs. With a focus on user-centric design and cutting-edge technology, we create apps that deliver seamless functionality and exceptional user experiences. Our expertise in app development can help you unlock the full potential of the digital landscape.',
            link: 'service-details-App-Development',
            index: '05'
        },
        {
            img: printDesignImg,
            title: 'Print Design',
            description: 'Our Print Design service brings your brand to life in the physical world. We specialize in creating eye-catching and professionally crafted print materials. From brochures to business cards, our designs are tailored to convey your message effectively and leave a lasting impression. Trust us to turn your ideas in captivating printed assets that stand out and leave a lasting impression.',
            link: 'service-details-print',
            index: '06'
        },
        {
            img: animationImg,
            title: 'Animated Videos',
            description: 'Our Animated Video service brings stories to life through captivating visuals and creative storytelling. We specialize in creating high-quality animated videos that engage, inform, and entertain your audience. Our team combines artistic talent with technical know-how to produce videos that have a lasting impression. Elevate your content with our animated video solutions and stand out in the world of visual storytelling.',
            link: 'service-details-animated-videos',
            index: '07'
        }
    ];

    const form = useRef();
    const [formSubmitted, setFormSubmitted] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);

        const fields = ["firstname", "lastname", "company", "phone", "email", "country", "service", "message"];

        let isValid = true;

        fields.forEach((field) => {
            if (!formData.get(field)) {
                setErrorMessage("Please fill out all fields.");
                isValid = false;
            }
        });

        if (isValid) {
            emailjs.sendForm('service_c27tjlo', 'template_i9sxgr7', form.current, 'Clns6xgEFImFe8Zja',)
                .then((result) => {
                    console.log(result.text);
                    setFormSubmitted(true);
                    setErrorMessage('');
                }, (error) => {
                    console.log(error.text);
                    setFormSubmitted(false);
                });
        }
    };

    useEffect(() => {
        const wow = new WOW.WOW();
        wow.init();
    }, []);

    return (
        <>
            <div className="bg-6 tt-magic-cursor">
                <div className="bg-6 tt-magic-cursor">
                    <div className="banner-area6">
                        <div className="banner-wrap">
                            <div className="social-area">
                                <ul>
                                    <li><a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-twitter"></i></a></li>
                                    <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><i className="bx bxl-instagram"></i></a></li>
                                </ul>
                            </div>
                            <div className="row">
                                <div className="col-xxl-6 col-lg-5 d-flex align-items-center">
                                    <div className="banner-content hero-section">
                                        <h1 className="animate__animated animate__fadeInRight hero-heading">Transforming Your
                                            <span>  Imaginations</span><br />
                                            into Solutions
                                        </h1>
                                        <p className="animate__animated animate__fadeInLeft hero-description">Welcome to Work Flow Optimization Web, your creative destination where vision turns
                                            into digital reality! We specialize in crafting unique digital identities that
                                            leave a lasting impression. Discover what we have to offer and unlock the full
                                            spectrum of creativity with us.
                                        </p>
                                        <div className="banner-btn-group">
                                            <Link className="primary-btn3" to="contact">Free Consultancy</Link>
                                            <Link className="primary-btn5" to="portfolio">
                                                <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1.51038 32.6729C-0.990382 34.1837 -0.232333 37.9945 2.65612 38.4335C4.97848 38.7866 6.09439 41.4804 4.70164 43.3722C2.96938 45.7248 5.12803 48.9555 7.9646 48.2555C10.245 47.6925 12.3074 49.7548 11.7445 52.0354C11.0445 54.8721 14.2752 57.0306 16.6278 55.2984C18.5196 53.9057 21.2139 55.0216 21.5665 57.3439C22.0054 60.2322 25.8161 60.9904 27.3271 58.4896C28.5419 56.479 31.4581 56.479 32.6729 58.4896C34.1837 60.9904 37.9945 60.2323 38.4335 57.3439C38.7866 55.0215 41.4804 53.9056 43.3722 55.2984C45.7248 57.0306 48.9555 54.872 48.2555 52.0354C47.6925 49.755 49.7548 47.6925 52.0354 48.2555C54.872 48.9555 57.0306 45.7248 55.2984 43.3722C53.9057 41.4804 55.0216 38.7861 57.3439 38.4335C60.2322 37.9946 60.9904 34.1839 58.4896 32.6729C56.479 31.4581 56.479 28.5419 58.4896 27.3271C60.9904 25.8163 60.2323 22.0055 57.3439 21.5665C55.0215 21.2134 53.9056 18.5196 55.2984 16.6278C57.0306 14.2752 54.872 11.0445 52.0354 11.7445C49.7549 12.3075 47.6925 10.2452 48.2555 7.9646C48.9555 5.12795 45.7248 2.96938 43.3722 4.70164C41.4804 6.09426 38.7861 4.9784 38.4335 2.65612C37.9946 -0.23224 34.1839 -0.990422 32.6729 1.51038C31.4581 3.52095 28.5419 3.52095 27.3271 1.51038C25.8163 -0.990382 22.0055 -0.232333 21.5665 2.65612C21.2134 4.97848 18.5196 6.09439 16.6278 4.70164C14.2752 2.96938 11.0445 5.12803 11.7445 7.9646C12.3075 10.245 10.2452 12.3074 7.9646 11.7445C5.12795 11.0445 2.96938 14.2752 4.70164 16.6278C6.09426 18.5196 4.9784 21.2139 2.65612 21.5665C-0.232239 22.0054 -0.990423 25.8161 1.51038 27.3271C3.52095 28.5419 3.52095 31.4581 1.51038 32.6729Z"></path>
                                                </svg>
                                                <div className="content">
                                                    VIEW all work
                                                    <span>
                                                        <svg width="12" height="12" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-6 col-lg-7 d-flex justify-content-end">
                                    <div className="banner-img-wrap">
                                        <Swiper
                                            modules={[Autoplay, Pagination]}
                                            spaceBetween={0}
                                            slidesPerView={1}
                                            loop
                                            autoplay={{ delay: 2000, disableOnInteraction: false }}
                                            pagination={{
                                                el: '.swiper-pagination',
                                                clickable: true,
                                            }}
                                            className="banner5-slider"
                                        >
                                            {bannerImages.map((img, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="banner-img">
                                                        <img className="img-fluid" src={img} alt={`Banner ${index + 1}`} />
                                                    </div>
                                                </SwiperSlide>
                                            ))}
                                        </Swiper>
                                    </div>
                                </div>

                                <div className="review-and-counter-area">
                                    <div className="customar-review">
                                        <h6>Review On</h6>
                                        <ul>
                                            <li className="single-review">
                                                <a href="#">
                                                    <div className="icon">
                                                        <img src={trustpilotImg} alt="Trustpilot" />
                                                    </div>
                                                    <ul className="star">
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li>5.0/5.0</li>
                                                    </ul>
                                                </a>
                                            </li>
                                            <li className="single-review">
                                                <a href="#">
                                                    <div className="icon">
                                                        <img src={googleImg} alt="Google" />
                                                    </div>
                                                    <ul className="star">
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li><i className="bi bi-star-fill"></i></li>
                                                        <li>5.0/5.0</li>
                                                    </ul>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="counter-area">
                                        <ul>
                                            <li>
                                                <div className="single-counter">
                                                    <div className="content">
                                                        <div className="number">
                                                            <h3 className="counter"><CountUp end={5} duration={15} /></h3>
                                                            <span>Years</span>
                                                        </div>
                                                        <p>Working With Passion</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-counter">
                                                    <div className="content">
                                                        <div className="number">
                                                            <h3 className="counter"><CountUp end={1.5} decimals={1} duration={15} /></h3>
                                                            <span className="ml-5">k</span>
                                                            <span>Customer</span>
                                                        </div>
                                                        <p>Satisfied Customer</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-counter">
                                                    <div className="content">
                                                        <div className="number">
                                                            <h3 className="counter"><CountUp end={800} duration={10} /></h3>
                                                            <span>Project</span>
                                                        </div>
                                                        <p>We Have Completed</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-counter">
                                                    <div className="content">
                                                        <div className="number">
                                                            <h3 className="counter"> <CountUp end={15} duration={15} /></h3>
                                                            <span>Awards</span>
                                                        </div>
                                                        <p>Achievement For Services</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home6-choose-section sec-mar">
                    <div className="container">
                        <div className="row">
                            <div className="choose-top">
                                <div className="row">
                                    <div className="col-lg-6 wow animate__animated animate__fadeInLeft" data-wow-delay="0.3s">
                                        <div className="choose-title">
                                            <span>Why Choose Us</span>
                                            <h2>Unlock the potential of your business.</h2>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 wow animate__animated animate__fadeInRight" data-wow-delay="0.5s">
                                        <div className="choose-right-img magnetic-item">
                                            <img className="img-fluid" src={chooseImg1} alt="Choose Us" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="choose-btm">
                                <div className="row g-lg-4 gy-5">
                                    <div className="col-lg-7">
                                        <div className="choose-left-content">
                                            <div className="vectors">
                                                <img className="choose-vec-top-r" src={chooseVecTopR} alt="Vector Top Right" />
                                                <img className="choose-vec-btm-l" src={chooseVecBtmL} alt="Vector Bottom Left" />
                                            </div>

                                            <h4>Best Creative IT Agency And Solutions <br />
                                                <span>Since 2018</span>
                                            </h4>
                                            <p>We've proudly led the way as the top-tier creative IT agency, delivering cutting-edge
                                                solutions that drive excellence and innovation in the digital landscape. Trust us to
                                                bring your IT visions to life.</p>
                                            <div className="sl">
                                                <h2>#1</h2>
                                            </div>
                                            <div className="about-btn">
                                                <a href="about">
                                                    <svg width="7" height="7" viewBox="0 0 13 13" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                                    </svg>
                                                    About More
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-5">
                                        <div className="choose-feature">
                                            <ul>
                                                <li>
                                                    <div className="single-feature">
                                                        <div className="progress">
                                                            <h3><span className="">01</span></h3>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Research & Discovery</h4>
                                                            <p>We believe that a strong foundation is key to success. Our dedicated
                                                                team dives deep into understanding your unique needs, market trends,
                                                                and preferences.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="single-feature">
                                                        <div className="progress">
                                                            <h3><span className="">02</span></h3>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Industry Expertise</h4>
                                                            <p>With years of experience and a passion for staying ahead of industry
                                                                trends, our team brings a wealth of expertise to the table.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="single-feature">
                                                        <div className="progress">
                                                            <h3><span className="">03</span></h3>
                                                        </div>
                                                        <div className="content">
                                                            <h4>Quality Assurance</h4>
                                                            <p>We employ rigorous quality assurance processes throughout the
                                                                development and design stages to ensure that your project meets the
                                                                highest standards.</p>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home6-solution-section sec-mar">
                    <div className="container">
                        <div className="row mb-55">
                            <div className="col-lg-12 wow">
                                <div className="section-title-6 text-center">
                                    <span>Services</span>
                                    <h2>Our Services</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    spaceBetween={30}
                                    slidesPerView={3}
                                    loop
                                    autoplay={{
                                        delay: 3000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                    }}

                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            spaceBetween: 10,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                            spaceBetween: 20,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 30,
                                        },
                                    }}

                                    className="home6-solution-slider"
                                >
                                    {services.map(({ img, title, description, link, index }) => (
                                        <SwiperSlide key={index}>
                                            <div className="single-solution">
                                                <div className="background-img">
                                                    <img src={img} alt={title} />
                                                </div>
                                                <div className="sl">
                                                    <h2>{index}</h2>
                                                </div>
                                                <div className="solution-content">
                                                    <h3>
                                                        <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
                                                    </h3>
                                                    <p>{description}</p>
                                                </div>
                                                <div className="solution-btn-icon">
                                                    <div className="learn-btn">
                                                        <a className="primary-btn9" href={link} target="_blank" rel="noopener noreferrer">
                                                            <span>Learn More</span>
                                                            <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L1.30274e-07 7.5" />
                                                            </svg>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="swiper-pagination"></div>

                <div className="home6-partner-area sce-mar">
                    <div className="container-fluid">
                        <div className="row mb-55">
                            <div
                                className="col-lg-12 wow">
                                <div className="section-title-6 text-center">
                                    <span>Our Clients</span>
                                    <h5>Our Satisfied Collaborators</h5>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="marque-wrap">
                                    <div className="marquee_text3">
                                        <div className="partner-logo">
                                            <img src={logo1Animal} alt="Animal Logo 1" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo2Animal} alt="Animal Logo 2" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo3Animal} alt="Animal Logo 3" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo4Animal} alt="Animal Logo 4" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo1Education} alt="Education Logo 1" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo2Education} alt="Education Logo 2" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo3Education} alt="Education Logo 3" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo4Education} alt="Education Logo 4" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo1Entertainment} alt="Entertainment Logo 1" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo2Entertainment} alt="Entertainment Logo 2" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo3Entertainment} alt="Entertainment Logo 3" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo4Entertainment} alt="Entertainment Logo 4" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo1Finance} alt="Finance Logo 1" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo2Finance} alt="Finance Logo 2" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo3Finance} alt="Finance Logo 3" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo4Finance} alt="Finance Logo 4" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo1HealthCare} alt="Health Care Logo 1" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo2Food} alt="Food Logo 2" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo3Food} alt="Food Logo 3" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo4Food} alt="Food Logo 4" />
                                        </div>
                                        <div className="partner-logo">
                                            <img src={logo1Marketing} alt="Marketing Logo 1" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home6-testimonial-area sec-mar">
                    <div className="container">
                        <div className="row mb-55">
                            <div className="col-lg-12 wow">
                                <div className="section-title-6 text-center">
                                    <span>Testimonials</span>
                                    <h2>Testimonials</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-8">
                                <Swiper
                                    modules={[Autoplay, Pagination]}
                                    spaceBetween={30}
                                    slidesPerView={1}
                                    loop={true}
                                    autoplay={{
                                        delay: 2000,
                                        disableOnInteraction: false,
                                    }}
                                    pagination={{
                                        el: '.swiper-pagination',
                                        clickable: true,
                                    }}
                                    className="home6-testimonial-slider"
                                >
                                    {[
                                        {
                                            reviewImg: testimonialReviewImg,
                                            content: 'When it came to app development, WMO Web proved to be the right choice. They delivered a sleek and intuitive app that our users love. Their commitment to deadlines and communication throughout the process made it a seamless experience.',
                                            author: 'Chris Ross',
                                            position: 'General Manager TechWizards',
                                        },
                                        {
                                            reviewImg: testimonialReviewImg,
                                            content: 'As a small business owner, I needed a website that stood out. WMO Web not only designed a beautiful website but also provided ongoing support to ensure its success. Their comprehensive approach makes them a trusted partner.',
                                            author: 'Mike',
                                            position: "Owner of Mike's Coffee Shop",
                                        },
                                    ].map(({ reviewImg, content, author, position }, index) => (
                                        <SwiperSlide key={index}>
                                            <div className="testimonial-wrapper">
                                                <div className="client-review">
                                                    <img src={reviewImg} alt="Client Review" />
                                                </div>
                                                <div className="testimonial-content">
                                                    <p>{content}</p>
                                                </div>
                                                <div className="author-area">
                                                    <h4>{author}</h4>
                                                    <span>{position}</span>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                                <div className="swiper-pagination"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home6-contact-section sec-mar">
                    <div className="container">
                        <div className="row g-lg-4 gy-5 justify-content-center">
                            <div className="col-lg-6 wow">
                                <div className="section-title-6 text-center">
                                    <span>Contact</span>
                                    <h2>Contact Us</h2>
                                </div>
                                <div className="contact-content">
                                    <p>We are here to answer any questions you may have and help you choose the service that best meets your needs.</p>
                                    <div className="contact-number animate__animated animate__fadeInRight" style={{ animationDelay: '5s' }}>
                                        <p>Call Us Now: <a href="tel:(+1) 888-906-2250">(+1) 888-906-2250</a></p>
                                    </div>
                                    <div className="section-title-6 text-center">
                                        <span>FAQs</span>
                                        <h2>FAQs</h2>
                                    </div>
                                    <div className="contact-step animate__animated animate__fadeInLeft" style={{ animationDelay: '5s' }}>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        How do I get started with a project at WMO Web?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        It's easy to get started! Just contact us through our website or by email, and our team will be in touch to talk about your project needs and send you a custom proposal.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        How long does it take to complete a project?
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Each project's timeline will vary depending on its complexity and your needs. We'll include a project timeline in our proposal and work hard to meet deadlines.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        What makes WMO Web unique compared to other design agencies?
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        We are passionate about providing high-quality, custom solutions that meet your specific needs. Our team of experienced professionals is dedicated to your success, and we offer competitive pricing and outstanding customer service.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingFour">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Is my information and project data secure with WMO Web?
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        Yes, we make data security a top priority. Your information and project data are kept confidential and safe. We follow strict privacy and security protocols.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-form-wrap">
                                    <div className="form-title">
                                        <h5>Request a Quote</h5>
                                    </div>
                                    <div className="contact-form">
                                        <form ref={form} onSubmit={sendEmail}>
                                            <div className="row animate__animated animate__zoomIn" style={{ animationDelay: '5s' }}>
                                                <div className="col-md-6 mb-20">
                                                    <div className="form-inner">
                                                        <label>First Name</label>
                                                        <input type="text" name="firstname" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-20">
                                                    <div className="form-inner">
                                                        <label>Last Name</label>
                                                        <input type="text" name="lastname" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Company/Organization</label>
                                                        <input type="text" name="company" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Phone</label>
                                                        <input type="tel" name="phone" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Email</label>
                                                        <input type="email" name="email" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="country">Country</label>
                                                        <select id="country" name="country" required style={{ width: '100%', background: 'rgba(255, 255, 255, .04)', border: '1px solid rgba(255, 255, 255, .05)', borderRadius: '5px', fontFamily: 'var(--font-saira)', fontWeight: '400', fontSize: '14px', letterSpacing: '.02em', color: '#dedada', padding: '10px 15px' }}>                                                           
                                                            <option style={{ color: 'black' }} value="">------Select Country------</option>
                                                            <option style={{ color: 'black' }} value="Afganistan">Afganistan</option>
                                                            <option style={{ color: 'black' }} value="Aland Islands">Aland Islands</option>
                                                            <option style={{ color: 'black' }} value="Albania">Albania</option>
                                                            <option style={{ color: 'black' }} value="Algeria">Algeria</option>
                                                            <option style={{ color: 'black' }} value="American Samoa">American Samoa</option>
                                                            <option style={{ color: 'black' }} value="Andorra">Andorra</option>
                                                            <option style={{ color: 'black' }} value="Angola">Angola</option>
                                                            <option style={{ color: 'black' }} value="Anguilla">Anguilla</option>
                                                            <option style={{ color: 'black' }} value="Antarctica">Antarctica</option>
                                                            <option style={{ color: 'black' }} value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option style={{ color: 'black' }} value="Argentina">Argentina</option>
                                                            <option style={{ color: 'black' }} value="Armenia">Armenia</option>
                                                            <option style={{ color: 'black' }} value="Aruba">Aruba</option>
                                                            <option style={{ color: 'black' }} value="Australia">Australia</option>
                                                            <option style={{ color: 'black' }} value="Austria">Austria</option>
                                                            <option style={{ color: 'black' }} value="Azerbaijan">Azerbaijan</option>
                                                            <option style={{ color: 'black' }} value="Bahamas">Bahamas</option>
                                                            <option style={{ color: 'black' }} value="Bahrain">Bahrain</option>
                                                            <option style={{ color: 'black' }} value="Bangladesh">Bangladesh</option>
                                                            <option style={{ color: 'black' }} value="Barbados">Barbados</option>
                                                            <option style={{ color: 'black' }} value="Belarus">Belarus</option>
                                                            <option style={{ color: 'black' }} value="Belgium">Belgium</option>
                                                            <option style={{ color: 'black' }} value="Belize">Belize</option>
                                                            <option style={{ color: 'black' }} value="Benin">Benin</option>
                                                            <option style={{ color: 'black' }} value="Bermuda">Bermuda</option>
                                                            <option style={{ color: 'black' }} value="Bhutan">Bhutan</option>
                                                            <option style={{ color: 'black' }} value="Bolivia">Bolivia</option>
                                                            <option style={{ color: 'black' }} value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                            <option style={{ color: 'black' }} value="Botswana">Botswana</option>
                                                            <option style={{ color: 'black' }} value="Bouvet Island">Bouvet Island</option>
                                                            <option style={{ color: 'black' }} value="Brazil">Brazil</option>
                                                            <option style={{ color: 'black' }} value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option style={{ color: 'black' }} value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option style={{ color: 'black' }} value="Bulgaria">Bulgaria</option>
                                                            <option style={{ color: 'black' }} value="Burkina Faso">Burkina Faso</option>
                                                            <option style={{ color: 'black' }} value="Burundi">Burundi</option>
                                                            <option style={{ color: 'black' }} value="Cambodia">Cambodia</option>
                                                            <option style={{ color: 'black' }} value="Cameroon">Cameroon</option>
                                                            <option style={{ color: 'black' }} value="Canada">Canada</option>
                                                            <option style={{ color: 'black' }} value="Cape Verde">Cape Verde</option>
                                                            <option style={{ color: 'black' }} value="Cayman Islands">Cayman Islands</option>
                                                            <option style={{ color: 'black' }} value="Central African Republic">Central African Republic</option>
                                                            <option style={{ color: 'black' }} value="Chad">Chad</option>
                                                            <option style={{ color: 'black' }} value="Chile">Chile</option>
                                                            <option style={{ color: 'black' }} value="China">China</option>
                                                            <option style={{ color: 'black' }} value="Christmas Island">Christmas Island</option>
                                                            <option style={{ color: 'black' }} value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option style={{ color: 'black' }} value="Colombia">Colombia</option>
                                                            <option style={{ color: 'black' }} value="Comoros">Comoros</option>
                                                            <option style={{ color: 'black' }} value="Congo">Congo</option>
                                                            <option style={{ color: 'black' }} value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                            <option style={{ color: 'black' }} value="Cook Islands">Cook Islands</option>
                                                            <option style={{ color: 'black' }} value="Costa Rica">Costa Rica</option>
                                                            <option style={{ color: 'black' }} value="Cote D'ivoire">Cote D'ivoire</option>
                                                            <option style={{ color: 'black' }} value="Croatia">Croatia</option>
                                                            <option style={{ color: 'black' }} value="Cuba">Cuba</option>
                                                            <option style={{ color: 'black' }} value="Cyprus">Cyprus</option>
                                                            <option style={{ color: 'black' }} value="Czech Republic">Czech Republic</option>
                                                            <option style={{ color: 'black' }} value="Denmark">Denmark</option>
                                                            <option style={{ color: 'black' }} value="Djibouti">Djibouti</option>
                                                            <option style={{ color: 'black' }} value="Dominica">Dominica</option>
                                                            <option style={{ color: 'black' }} value="Dominican Republic">Dominican Republic</option>
                                                            <option style={{ color: 'black' }} value="Ecuador">Ecuador</option>
                                                            <option style={{ color: 'black' }} value="Egypt">Egypt</option>
                                                            <option style={{ color: 'black' }} value="El Salvador">El Salvador</option>
                                                            <option style={{ color: 'black' }} value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option style={{ color: 'black' }} value="Eritrea">Eritrea</option>
                                                            <option style={{ color: 'black' }} value="Estonia">Estonia</option>
                                                            <option style={{ color: 'black' }} value="Ethiopia">Ethiopia</option>
                                                            <option style={{ color: 'black' }} value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                            <option style={{ color: 'black' }} value="Faroe Islands">Faroe Islands</option>
                                                            <option style={{ color: 'black' }} value="Fiji">Fiji</option>
                                                            <option style={{ color: 'black' }} value="Finland">Finland</option>
                                                            <option style={{ color: 'black' }} value="France">France</option>
                                                            <option style={{ color: 'black' }} value="French Guiana">French Guiana</option>
                                                            <option style={{ color: 'black' }} value="French Polynesia">French Polynesia</option>
                                                            <option style={{ color: 'black' }} value="French Southern Territories">French Southern Territories</option>
                                                            <option style={{ color: 'black' }} value="Gabon">Gabon</option>
                                                            <option style={{ color: 'black' }} value="Gambia">Gambia</option>
                                                            <option style={{ color: 'black' }} value="Georgia">Georgia</option>
                                                            <option style={{ color: 'black' }} value="Germany">Germany</option>
                                                            <option style={{ color: 'black' }} value="Ghana">Ghana</option>
                                                            <option style={{ color: 'black' }} value="Gibraltar">Gibraltar</option>
                                                            <option style={{ color: 'black' }} value="Greece">Greece</option>
                                                            <option style={{ color: 'black' }} value="Greenland">Greenland</option>
                                                            <option style={{ color: 'black' }} value="Grenada">Grenada</option>
                                                            <option style={{ color: 'black' }} value="Guadeloupe">Guadeloupe</option>
                                                            <option style={{ color: 'black' }} value="Guam">Guam</option>
                                                            <option style={{ color: 'black' }} value="Guatemala">Guatemala</option>
                                                            <option style={{ color: 'black' }} value="Guernsey">Guernsey</option>
                                                            <option style={{ color: 'black' }} value="Guinea">Guinea</option>
                                                            <option style={{ color: 'black' }} value="Guinea-bissau">Guinea-bissau</option>
                                                            <option style={{ color: 'black' }} value="Guyana">Guyana</option>
                                                            <option style={{ color: 'black' }} value="Haiti">Haiti</option>
                                                            <option style={{ color: 'black' }} value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                            <option style={{ color: 'black' }} value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                            <option style={{ color: 'black' }} value="Honduras">Honduras</option>
                                                            <option style={{ color: 'black' }} value="Hong Kong">Hong Kong</option>
                                                            <option style={{ color: 'black' }} value="Hungary">Hungary</option>
                                                            <option style={{ color: 'black' }} value="Iceland">Iceland</option>
                                                            <option style={{ color: 'black' }} value="India">India</option>
                                                            <option style={{ color: 'black' }} value="Indonesia">Indonesia</option>
                                                            <option style={{ color: 'black' }} value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                            <option style={{ color: 'black' }} value="Iraq">Iraq</option>
                                                            <option style={{ color: 'black' }} value="Ireland">Ireland</option>
                                                            <option style={{ color: 'black' }} value="Isle of Man">Isle of Man</option>
                                                            <option style={{ color: 'black' }} value="Israel">Israel</option>
                                                            <option style={{ color: 'black' }} value="Italy">Italy</option>
                                                            <option style={{ color: 'black' }} value="Jamaica">Jamaica</option>
                                                            <option style={{ color: 'black' }} value="Japan">Japan</option>
                                                            <option style={{ color: 'black' }} value="Jersey">Jersey</option>
                                                            <option style={{ color: 'black' }} value="Jordan">Jordan</option>
                                                            <option style={{ color: 'black' }} value="Kazakhstan">Kazakhstan</option>
                                                            <option style={{ color: 'black' }} value="Kenya">Kenya</option>
                                                            <option style={{ color: 'black' }} value="Kiribati">Kiribati</option>
                                                            <option style={{ color: 'black' }} value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                            <option style={{ color: 'black' }} value="Korea, Republic of">Korea, Republic of</option>
                                                            <option style={{ color: 'black' }} value="Kuwait">Kuwait</option>
                                                            <option style={{ color: 'black' }} value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option style={{ color: 'black' }} value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                            <option style={{ color: 'black' }} value="Latvia">Latvia</option>
                                                            <option style={{ color: 'black' }} value="Lebanon">Lebanon</option>
                                                            <option style={{ color: 'black' }} value="Lesotho">Lesotho</option>
                                                            <option style={{ color: 'black' }} value="Liberia">Liberia</option>
                                                            <option style={{ color: 'black' }} value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option style={{ color: 'black' }} value="Liechtenstein">Liechtenstein</option>
                                                            <option style={{ color: 'black' }} value="Lithuania">Lithuania</option>
                                                            <option style={{ color: 'black' }} value="Luxembourg">Luxembourg</option>
                                                            <option style={{ color: 'black' }} value="Macao">Macao</option>
                                                            <option style={{ color: 'black' }} value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option style={{ color: 'black' }} value="Madagascar">Madagascar</option>
                                                            <option style={{ color: 'black' }} value="Malawi">Malawi</option>
                                                            <option style={{ color: 'black' }} value="Malaysia">Malaysia</option>
                                                            <option style={{ color: 'black' }} value="Maldives">Maldives</option>
                                                            <option style={{ color: 'black' }} value="Mali">Mali</option>
                                                            <option style={{ color: 'black' }} value="Malta">Malta</option>
                                                            <option style={{ color: 'black' }} value="Marshall Islands">Marshall Islands</option>
                                                            <option style={{ color: 'black' }} value="Martinique">Martinique</option>
                                                            <option style={{ color: 'black' }} value="Mauritania">Mauritania</option>
                                                            <option style={{ color: 'black' }} value="Mauritius">Mauritius</option>
                                                            <option style={{ color: 'black' }} value="Mayotte">Mayotte</option>
                                                            <option style={{ color: 'black' }} value="Mexico">Mexico</option>
                                                            <option style={{ color: 'black' }} value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                            <option style={{ color: 'black' }} value="Moldova, Republic of">Moldova, Republic of</option>
                                                            <option style={{ color: 'black' }} value="Monaco">Monaco</option>
                                                            <option style={{ color: 'black' }} value="Mongolia">Mongolia</option>
                                                            <option style={{ color: 'black' }} value="Montenegro">Montenegro</option>
                                                            <option style={{ color: 'black' }} value="Montserrat">Montserrat</option>
                                                            <option style={{ color: 'black' }} value="Morocco">Morocco</option>
                                                            <option style={{ color: 'black' }} value="Mozambique">Mozambique</option>
                                                            <option style={{ color: 'black' }} value="Myanmar">Myanmar</option>
                                                            <option style={{ color: 'black' }} value="Namibia">Namibia</option>
                                                            <option style={{ color: 'black' }} value="Nauru">Nauru</option>
                                                            <option style={{ color: 'black' }} value="Nepal">Nepal</option>
                                                            <option style={{ color: 'black' }} value="Netherlands">Netherlands</option>
                                                            <option style={{ color: 'black' }} value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option style={{ color: 'black' }} value="New Caledonia">New Caledonia</option>
                                                            <option style={{ color: 'black' }} value="New Zealand">New Zealand</option>
                                                            <option style={{ color: 'black' }} value="Nicaragua">Nicaragua</option>
                                                            <option style={{ color: 'black' }} value="Niger">Niger</option>
                                                            <option style={{ color: 'black' }} value="Nigeria">Nigeria</option>
                                                            <option style={{ color: 'black' }} value="Niue">Niue</option>
                                                            <option style={{ color: 'black' }} value="Norfolk Island">Norfolk Island</option>
                                                            <option style={{ color: 'black' }} value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option style={{ color: 'black' }} value="Norway">Norway</option>
                                                            <option style={{ color: 'black' }} value="Oman">Oman</option>
                                                            <option style={{ color: 'black' }} value="Pakistan">Pakistan</option>
                                                            <option style={{ color: 'black' }} value="Palau">Palau</option>
                                                            <option style={{ color: 'black' }} value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                            <option style={{ color: 'black' }} value="Panama">Panama</option>
                                                            <option style={{ color: 'black' }} value="Papua New Guinea">Papua New Guinea</option>
                                                            <option style={{ color: 'black' }} value="Paraguay">Paraguay</option>
                                                            <option style={{ color: 'black' }} value="Peru">Peru</option>
                                                            <option style={{ color: 'black' }} value="Philippines">Philippines</option>
                                                            <option style={{ color: 'black' }} value="Pitcairn">Pitcairn</option>
                                                            <option style={{ color: 'black' }} value="Poland">Poland</option>
                                                            <option style={{ color: 'black' }} value="Portugal">Portugal</option>
                                                            <option style={{ color: 'black' }} value="Puerto Rico">Puerto Rico</option>
                                                            <option style={{ color: 'black' }} value="Qatar">Qatar</option>
                                                            <option style={{ color: 'black' }} value="Reunion">Reunion</option>
                                                            <option style={{ color: 'black' }} value="Romania">Romania</option>
                                                            <option style={{ color: 'black' }} value="Russian Federation">Russian Federation</option>
                                                            <option style={{ color: 'black' }} value="Rwanda">Rwanda</option>
                                                            <option style={{ color: 'black' }} value="Saint Helena">Saint Helena</option>
                                                            <option style={{ color: 'black' }} value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option style={{ color: 'black' }} value="Saint Lucia">Saint Lucia</option>
                                                            <option style={{ color: 'black' }} value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                            <option style={{ color: 'black' }} value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                            <option style={{ color: 'black' }} value="Samoa">Samoa</option>
                                                            <option style={{ color: 'black' }} value="San Marino">San Marino</option>
                                                            <option style={{ color: 'black' }} value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option style={{ color: 'black' }} value="Saudi Arabia">Saudi Arabia</option>
                                                            <option style={{ color: 'black' }} value="Senegal">Senegal</option>
                                                            <option style={{ color: 'black' }} value="Serbia">Serbia</option>
                                                            <option style={{ color: 'black' }} value="Seychelles">Seychelles</option>
                                                            <option style={{ color: 'black' }} value="Sierra Leone">Sierra Leone</option>
                                                            <option style={{ color: 'black' }} value="Singapore">Singapore</option>
                                                            <option style={{ color: 'black' }} value="Slovakia">Slovakia</option>
                                                            <option style={{ color: 'black' }} value="Slovenia">Slovenia</option>
                                                            <option style={{ color: 'black' }} value="Solomon Islands">Solomon Islands</option>
                                                            <option style={{ color: 'black' }} value="Somalia">Somalia</option>
                                                            <option style={{ color: 'black' }} value="South Africa">South Africa</option>
                                                            <option style={{ color: 'black' }} value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                            <option style={{ color: 'black' }} value="Spain">Spain</option>
                                                            <option style={{ color: 'black' }} value="Sri Lanka">Sri Lanka</option>
                                                            <option style={{ color: 'black' }} value="Sudan">Sudan</option>
                                                            <option style={{ color: 'black' }} value="Suriname">Suriname</option>
                                                            <option style={{ color: 'black' }} value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                            <option style={{ color: 'black' }} value="Swaziland">Swaziland</option>
                                                            <option style={{ color: 'black' }} value="Sweden">Sweden</option>
                                                            <option style={{ color: 'black' }} value="Switzerland">Switzerland</option>
                                                            <option style={{ color: 'black' }} value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option style={{ color: 'black' }} value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                            <option style={{ color: 'black' }} value="Tajikistan">Tajikistan</option>
                                                            <option style={{ color: 'black' }} value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                            <option style={{ color: 'black' }} value="Thailand">Thailand</option>
                                                            <option style={{ color: 'black' }} value="Timor-leste">Timor-leste</option>
                                                            <option style={{ color: 'black' }} value="Togo">Togo</option>
                                                            <option style={{ color: 'black' }} value="Tokelau">Tokelau</option>
                                                            <option style={{ color: 'black' }} value="Tonga">Tonga</option>
                                                            <option style={{ color: 'black' }} value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option style={{ color: 'black' }} value="Tunisia">Tunisia</option>
                                                            <option style={{ color: 'black' }} value="Turkey">Turkey</option>
                                                            <option style={{ color: 'black' }} value="Turkmenistan">Turkmenistan</option>
                                                            <option style={{ color: 'black' }} value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option style={{ color: 'black' }} value="Tuvalu">Tuvalu</option>
                                                            <option style={{ color: 'black' }} value="Uganda">Uganda</option>
                                                            <option style={{ color: 'black' }} value="Ukraine">Ukraine</option>
                                                            <option style={{ color: 'black' }} value="United Arab Emirates">United Arab Emirates</option>
                                                            <option style={{ color: 'black' }} value="United Kingdom">United Kingdom</option>
                                                            <option style={{ color: 'black' }} value="United States">United States</option>
                                                            <option style={{ color: 'black' }} value="Uruguay">Uruguay</option>
                                                            <option style={{ color: 'black' }} value="Uzbekistan">Uzbekistan</option>
                                                            <option style={{ color: 'black' }} value="Vanuatu">Vanuatu</option>
                                                            <option style={{ color: 'black' }} value="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
                                                            <option style={{ color: 'black' }} value="Venezuela">Venezuela</option>
                                                            <option style={{ color: 'black' }} value="Viet Nam">Viet Nam</option>
                                                            <option style={{ color: 'black' }} value="Virgin Islands, British">Virgin Islands, British</option>
                                                            <option style={{ color: 'black' }} value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                            <option style={{ color: 'black' }} value="Wallis and Futuna">Wallis and Futuna</option>
                                                            <option style={{ color: 'black' }} value="Western Sahara">Western Sahara</option>
                                                            <option style={{ color: 'black' }} value="Yemen">Yemen</option>
                                                            <option style={{ color: 'black' }} value="Zambia">Zambia</option>
                                                            <option style={{ color: 'black' }} value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="services">Services</label>
                                                        <select id="services" name="service" required style={{ width: '100%', background: 'rgba(255, 255, 255, .04)', border: '1px solid rgba(255, 255, 255, .05)', borderRadius: '5px', color: '#dedada', padding: '10px 15px' }}>
                                                            <option style={{ color: 'black' }} value="">------Select Service------</option>
                                                            <option style={{ color: 'black' }} value="Web Design & Development">Web Design & Development</option>
                                                            <option style={{ color: 'black' }} value="Mobile Website">Mobile Website</option>
                                                            <option style={{ color: 'black' }} value="E-Commerce">E-Commerce</option>
                                                            <option style={{ color: 'black' }} value="Logo Design">Logo Design</option>
                                                            <option style={{ color: 'black' }} value="App Development">App Development</option>
                                                            <option style={{ color: 'black' }} value="Print Design">Print Design</option>
                                                            <option style={{ color: 'black' }} value="Animated Videos">Animated Videos</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="message">Message</label>
                                                        <textarea id="message" name="message"></textarea>
                                                    </div>
                                                </div>
                                                {formSubmitted === true && (
                                                    <div className="confirmation-message success">
                                                        <p className='contact-para'>Thank you! Your form has been submitted successfully.</p>
                                                    </div>
                                                )}
                                                {errorMessage && (
                                                    <div className="confirmation-message error">
                                                        <p className='contact-para1'>{errorMessage}</p>
                                                    </div>
                                                )}
                                                <div className="col-lg-12">
                                                    <div className="form-inner">
                                                        <button className="primary-btn3" type="submit">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
