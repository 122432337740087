import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'animate.css';

import banner1 from '../assets/img/inner-pages/inner-banner-1.png';
import banner2 from '../assets/img/inner-pages/inner-banner-2.png';
import arrowIcon from '../assets/img/inner-pages/breadcrumb-arrow.svg';

import beImg from '../assets/img/portfolio/be.jpg';
import claudiaImg from '../assets/img/portfolio/claudia.jpg';
import wcuImg from '../assets/img/portfolio/wcu.jpg';
import momcslImg from '../assets/img/portfolio/momcsl.jpg';
import psydocImg from '../assets/img/portfolio/psydoc.jpg';
import incImg from '../assets/img/portfolio/inc.jpg';
import genxImg from '../assets/img/portfolio/genx.jpg';
import mzfImg from '../assets/img/portfolio/mzf.jpg';
import img7 from '../assets/img/portfolio/img7.jpg';
import img9 from '../assets/img/portfolio/img9.jpg';
import img13 from '../assets/img/portfolio/img13.jpg';
import img12 from '../assets/img/portfolio/img12.jpg';
import img5 from '../assets/img/portfolio/img5.jpg';
import logo46 from '../assets/img/portfolio/logos/46.jpg';
import logoWeb1 from '../assets/img/portfolio/logos/web-and-logo-portfolio-1.jpg';
import logo42 from '../assets/img/portfolio/logos/42.jpg';
import logo41 from '../assets/img/portfolio/logos/41.jpg';
import logo35 from '../assets/img/portfolio/logos/35.jpg';
import logo48 from '../assets/img/portfolio/logos/48.jpg';
import logoWeb2 from '../assets/img/portfolio/logos/web-and-logo-portfolio-2.jpg';
import logo26 from '../assets/img/portfolio/logos/26.jpg';
import logo25 from '../assets/img/portfolio/logos/25.jpg';
import logo23 from '../assets/img/portfolio/logos/23.jpg';
import logo19 from '../assets/img/portfolio/logos/19.jpg';
import logo18 from '../assets/img/portfolio/logos/18.jpg';
import logo16 from '../assets/img/portfolio/logos/16.jpg';
import logo15 from '../assets/img/portfolio/logos/15.jpg';
import logo13 from '../assets/img/portfolio/logos/13.jpg';
import logo8 from '../assets/img/portfolio/logos/8.jpg';
import logo14 from '../assets/img/portfolio/logos/14.jpg';
import logo4 from '../assets/img/portfolio/logos/4.jpg';
import logo3 from '../assets/img/portfolio/logos/3.jpg';
import cardCloud from '../assets/img/portfolio/logos/aap-dev-lightbox/card-cloud/card-cloud.png';
import athan from '../assets/img/portfolio/logos/aap-dev-lightbox/athan/media-thumb.png';
import imageComment from '../assets/img/portfolio/logos/aap-dev-lightbox/image-comment/media-thumb.png';
import creditAlert from '../assets/img/portfolio/logos/aap-dev-lightbox/credit-alert/credit-alert.png';
import benefitTools from '../assets/img/portfolio/logos/aap-dev-lightbox/benefit-tools/media-thumb.png';
import awesomeNote from '../assets/img/portfolio/logos/aap-dev-lightbox/awesome-note/media-thumb.png';
import sprintCenter from '../assets/img/portfolio/logos/aap-dev-lightbox/sprint-center/media-thumb.png';
import media from '../assets/img/portfolio/logos/aap-dev-lightbox/media/media-thumb.png';

const developingItems = [
    { href: 'http://www.edab.com/', src: img7, alt: 'EDAB' },
    { href: 'http://efxusa.com/', src: img9, alt: 'EFX USA' },
    { href: 'http://www.seikoclocks.com/', src: img13, alt: 'Seiko Clocks' },
    { href: 'http://www.intheholegolf.com/', src: img12, alt: 'In the Hole Golf' },
    { href: 'http://www.southoftenoutfitters.com/', src: img5, alt: 'South Often Outfitters' }
];

const uiItems = [
    { src: logo46, alt: 'Logo 46' },
    { src: logoWeb1, alt: 'Web and Logo Portfolio 1' },
    { src: logo42, alt: 'Logo 42' },
    { src: logo41, alt: 'Logo 41' },
    { src: logo35, alt: 'Logo 35' },
    { src: logo48, alt: 'Logo 48' },
    { src: logoWeb2, alt: 'Web and Logo Portfolio 2' },
    { src: logo26, alt: 'Logo 26' },
    { src: logo25, alt: 'Logo 25' },
    { src: logo23, alt: 'Logo 23' },
    { src: logo19, alt: 'Logo 19' },
    { src: logo18, alt: 'Logo 18' },
    { src: logo16, alt: 'Logo 16' },
    { src: logo15, alt: 'Logo 15' },
    { src: logo13, alt: 'Logo 13' },
    { src: logo8, alt: 'Logo 8' },
    { src: logo14, alt: 'Logo 14' },
    { src: logo4, alt: 'Logo 4' },
    { src: logo3, alt: 'Logo 3' }
];

const graphicItems = [
    { src: cardCloud, alt: 'Card Cloud' },
    { src: athan, alt: 'Athan' },
    { src: imageComment, alt: 'Image Comment' },
    { src: creditAlert, alt: 'Credit Alert' },
    { src: benefitTools, alt: 'Benefit Tools' },
    { src: awesomeNote, alt: 'Awesome Note' },
    { src: sprintCenter, alt: 'Sprint Center' },
    { src: media, alt: 'Media' }
];

const portfolioItems = [
    {
        href: 'https://www.biocoreessentials.com/',
        src: beImg,
        alt: 'BioCore Essentials'
    },
    {
        href: 'http://www.claudiabader.com/',
        src: claudiaImg,
        alt: 'Claudia Bader'
    },
    {
        href: 'https://whatcanwedoforyou.com/',
        src: wcuImg,
        alt: 'What Can We Do For You'
    },
    {
        href: 'http://www.momcsl.com/',
        src: momcslImg,
        alt: 'MOM CSL'
    },
    {
        href: 'http://www.psydoc.net/',
        src: psydocImg,
        alt: 'PsyDoc'
    },
    {
        href: 'http://www.islandneurocare.com/',
        src: incImg,
        alt: 'Island Neurocare'
    },
    {
        href: 'https://www.genxhealthcare.com/',
        src: genxImg,
        alt: 'GenX Healthcare'
    },
    {
        href: 'http://www.mzfmedical.com/',
        src: mzfImg,
        alt: 'MZF Medical'
    }
];

const Portfolio = () => {
    const [activeFilter, setActiveFilter] = useState('*');

    const handleFilterChange = (filter) => {
        setActiveFilter(filter);
    };

    const filteredItems = {
        '*': [...portfolioItems, ...developingItems, ...uiItems, ...graphicItems],
        'ui': uiItems,
        'web': portfolioItems,
        'developing': developingItems,
        'graphic': graphicItems
    };

    return (
        <>
            <section className="breadcrumbs">
                <div className="breadcrumb-sm-images">
                <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                            <img src={banner1} alt="Banner 1" />
                        </div>
                        <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                            <img src={banner2} alt="Banner 2" />
                        </div>
                    </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-cnt">
                                    <span>Our Portfolio</span>
                                    <h1 className="animate__animated animate__slideInRight">Our Projects Portfolio</h1>
                                    <div className="breadcrumb-list">
                                        <Link to="/">Home</Link>
                                        <img src={arrowIcon} alt="Breadcrumb Arrow" />
                                        <span>Our Portfolio</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="portfolio-masonary-page sec-mar">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <ul className="isotope-menu">
                        <li
                                    className={activeFilter === '*' ? 'active' : ''}
                                    onClick={() => handleFilterChange('*')}
                                >
                                    All
                                </li>
                                <li
                                    className={activeFilter === 'ui' ? 'active' : ''}
                                    onClick={() => handleFilterChange('ui')}
                                >
                                    Logo
                                </li>
                                <li
                                    className={activeFilter === 'web' ? 'active' : ''}
                                    onClick={() => handleFilterChange('web')}
                                >
                                    Web Design
                                </li>
                                <li
                                    className={activeFilter === 'developing' ? 'active' : ''}
                                    onClick={() => handleFilterChange('developing')}
                                >
                                    E-Commerce
                                </li>
                                <li
                                    className={activeFilter === 'graphic' ? 'active' : ''}
                                    onClick={() => handleFilterChange('graphic')}
                                >
                                    Apps
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='row-g-4-project-items-mb-55'>
                        <div className="row g-4 project-items mb-55 ">
                            {filteredItems[activeFilter].map((item, index) => (
                                <div className={`col-lg-5 col-sm-6 single-item ${activeFilter}`} key={index}>
                                    <div className="single-work magnetic-item">
                                        <div className="work-img">
                                            <a href={item.href} target="_blank" rel="noopener noreferrer" title="">
                                                <img className="img-fluid" src={item.src} alt={item.alt} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Portfolio;