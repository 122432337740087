import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Service from './pages/Service';
import Portfolio from './pages/Portfolio';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import LogoPackage from "./LogoPackage";
import WebPackage from "./WebPackage";
import WebDesignDevelopment from "./pages/services/WebDesignDevelopment";
import MobileWebsite from "./pages/services/MobileWebsite";
import ECommerce from "./pages/services/ECommerce";
import LogoDesign from "./pages/services/LogoDesign";
import AppDevelopment from "./pages/services/AppDevelopment";
import PrintDesign from "./pages/services/PrintDesign";
import AnimatedVideos from "./pages/services/AnimatedVideos";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'boxicons/css/boxicons.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/swiper-bundle.min.css';

import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/service" element={<Service />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/logo-package" element={<LogoPackage />} />
          <Route path="/web-package" element={<WebPackage />} />
          <Route path="/service-details-web" element={<WebDesignDevelopment />} />
          <Route path="/service-details-mobile" element={<MobileWebsite />} />
          <Route path="/service-details-ecommerce" element={<ECommerce />} />
          <Route path="/service-details-logo" element={<LogoDesign />} />
          <Route path="/service-details-app-development" element={<AppDevelopment />} />
          <Route path="/service-details-print" element={<PrintDesign />} />
          <Route path="/service-details-animated-videos" element={<AnimatedVideos />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;