import React, { useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import emailjs from 'emailjs-com';

import InnerBanner1 from '../assets/img/inner-pages/inner-banner-1.png';
import InnerBanner2 from '../assets/img/inner-pages/inner-banner-2.png';
import icon from '../assets/img/icon.png';
import BreadcrumbArrow from '../assets/img/inner-pages/breadcrumb-arrow.svg';

const Contact = () => {

    const form = useRef();
    const [formSubmitted, setFormSubmitted] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const sendEmail = (e) => {
        e.preventDefault();

        const formData = new FormData(form.current);

        const fields = ["firstname", "lastname", "company", "phone", "email", "country", "service", "message"];

        let isValid = true;

        fields.forEach((field) => {
            if (!formData.get(field)) {
                setErrorMessage("Please fill out all fields.");
                isValid = false;
            }
        });

        if (isValid) {
            emailjs.sendForm('service_c27tjlo', 'template_i9sxgr7', form.current, '3pRzhoqAbBEd0X8pD',)
                .then((result) => {
                    console.log(result.text);
                    setFormSubmitted(true);
                    setErrorMessage('');
                }, (error) => {
                    console.log(error.text);
                    setFormSubmitted(false);
                });
        }
    };

    return (
        <>
            <div className="home-dark2 tt-magic-cursor">
                <section className="breadcrumbs">
                    <div className="breadcrumb-sm-images">
                        <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                            <img src={InnerBanner1} alt="Inner Banner 1" />
                        </div>
                        <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                            <img src={InnerBanner2} alt="Inner Banner 2" />
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-wrapper">
                                    <div className="breadcrumb-cnt">
                                        <span>Contact</span>
                                        <h1 className="animate__animated animate__slideInLeft">Connect With Us</h1>
                                        <div className="breadcrumb-list">
                                            <a href="/">Home</a>
                                            <img src={BreadcrumbArrow} alt="breadcrumb arrow" />
                                            Contact
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="contact-page-wrap sec-mar">
                    <div className="container">
                        <div className="row g-lg-4 gy-5">
                            <div className="col-lg-6">
                                <div className="contact-content">
                                    <span>CONTACT US</span>
                                    <h2>LET’S WORK TOGETHER?</h2>
                                    <div className="informations">
                                        <div className="single-info animate__animated animate__fadeInLeft" style={{animationDelay: '1s', animationDuration: '2s'}}>
                                            <div className="icon">
                                                <i className="fas fa-map-marker-alt"></i>
                                            </div>
                                            <div className="info">
                                                <p>
                                                    <a href="https://maps.app.goo.gl/wqEuGqzjTwDvjxpG7" target="_blank" rel="noopener noreferrer">
                                                        3440 Hollywood Blvd, Hollywood, Florida 33021, US
                                                    </a>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="single-info">
                                            <div className="icon">
                                                <img className="img-fluid animate__animated animate__tada" style={{animationDelay: '3s', animationDuration: '2s' }} src={icon} alt="Icon" />
                                            </div>
                                            <div className="info">
                                                <a href="tel:+18889062250">(+1) 888-906-2250</a>
                                            </div>
                                        </div>
                                        <div className="single-info animate__animated animate__fadeInRight" style={{animationDelay: '1s', animationDuration: '2s'}}>
                                            <div className="icon">
                                                <i className="far fa-envelope"></i>
                                            </div>
                                            <div className="info">
                                                <a href="mailto:info@workflowoptimization.us">info@workflowoptimization.us</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="follow-area">
                                        <h5 className="blog-widget-title">Follow Us</h5>
                                        <div className="blog-widget-body">
                                            <ul className="follow-list d-flex flex-row align-items-start gap-4">
                                                <li>
                                                    <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                                        <i className="bx bxl-facebook"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                                        <i className="bx bxl-twitter"></i>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                                        <i className="bx bxl-instagram"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-form-wrap">
                                    <div className="form-tltle">
                                        <h5>Request a Quote</h5>
                                    </div>
                                    <div className="contact-form">
                                        <form ref={form} onSubmit={sendEmail}>
                                            <div className="row animate__animated animate__fadeInRight" style={{animationDuration: '2s'}}>
                                                <div className="col-md-6 mb-20">
                                                    <div className="form-inner">
                                                        <label>First Name</label>
                                                        <input type="text" name="firstname" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mb-20">
                                                    <div className="form-inner">
                                                        <label>Last Name</label>
                                                        <input type="text" name="lastname" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Company/Organization</label>
                                                        <input type="text" name="company" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Phone</label>
                                                        <input type="tel" name="phone" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label>Email</label>
                                                        <input type="email" name="email" />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="country">Country</label>
                                                        <select id="country" name="country" required style={{ width: '100%', background: 'rgba(255, 255, 255, .04)', border: '1px solid rgba(255, 255, 255, .05)', borderRadius: '5px', fontFamily: 'var(--font-saira)', fontWeight: '400', fontSize: '14px', letterSpacing: '.02em', color: '#dedada', padding: '10px 15px' }}>
                                                            <option style={{ color: 'black' }} value="">------Select Country------</option>
                                                            <option style={{ color: 'black' }} value="Afghanistan">Afghanistan</option>
                                                            <option style={{ color: 'black' }} value="Aland Islands">Aland Islands</option>
                                                            <option style={{ color: 'black' }} value="Albania">Albania</option>
                                                            <option style={{ color: 'black' }} value="Algeria">Algeria</option>
                                                            <option style={{ color: 'black' }} value="American Samoa">American Samoa</option>
                                                            <option style={{ color: 'black' }} value="Andorra">Andorra</option>
                                                            <option style={{ color: 'black' }} value="Angola">Angola</option>
                                                            <option style={{ color: 'black' }} value="Anguilla">Anguilla</option>
                                                            <option style={{ color: 'black' }} value="Antarctica">Antarctica</option>
                                                            <option style={{ color: 'black' }} value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                            <option style={{ color: 'black' }} value="Argentina">Argentina</option>
                                                            <option style={{ color: 'black' }} value="Armenia">Armenia</option>
                                                            <option style={{ color: 'black' }} value="Aruba">Aruba</option>
                                                            <option style={{ color: 'black' }} value="Australia">Australia</option>
                                                            <option style={{ color: 'black' }} value="Austria">Austria</option>
                                                            <option style={{ color: 'black' }} value="Azerbaijan">Azerbaijan</option>
                                                            <option style={{ color: 'black' }} value="Bahamas">Bahamas</option>
                                                            <option style={{ color: 'black' }} value="Bahrain">Bahrain</option>
                                                            <option style={{ color: 'black' }} value="Bangladesh">Bangladesh</option>
                                                            <option style={{ color: 'black' }} value="Barbados">Barbados</option>
                                                            <option style={{ color: 'black' }} value="Belarus">Belarus</option>
                                                            <option style={{ color: 'black' }} value="Belgium">Belgium</option>
                                                            <option style={{ color: 'black' }} value="Belize">Belize</option>
                                                            <option style={{ color: 'black' }} value="Benin">Benin</option>
                                                            <option style={{ color: 'black' }} value="Bermuda">Bermuda</option>
                                                            <option style={{ color: 'black' }} value="Bhutan">Bhutan</option>
                                                            <option style={{ color: 'black' }} value="Bolivia">Bolivia</option>
                                                            <option style={{ color: 'black' }} value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                                            <option style={{ color: 'black' }} value="Botswana">Botswana</option>
                                                            <option style={{ color: 'black' }} value="Bouvet Island">Bouvet Island</option>
                                                            <option style={{ color: 'black' }} value="Brazil">Brazil</option>
                                                            <option style={{ color: 'black' }} value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                            <option style={{ color: 'black' }} value="Brunei Darussalam">Brunei Darussalam</option>
                                                            <option style={{ color: 'black' }} value="Bulgaria">Bulgaria</option>
                                                            <option style={{ color: 'black' }} value="Burkina Faso">Burkina Faso</option>
                                                            <option style={{ color: 'black' }} value="Burundi">Burundi</option>
                                                            <option style={{ color: 'black' }} value="Cambodia">Cambodia</option>
                                                            <option style={{ color: 'black' }} value="Cameroon">Cameroon</option>
                                                            <option style={{ color: 'black' }} value="Canada">Canada</option>
                                                            <option style={{ color: 'black' }} value="Cape Verde">Cape Verde</option>
                                                            <option style={{ color: 'black' }} value="Cayman Islands">Cayman Islands</option>
                                                            <option style={{ color: 'black' }} value="Central African Republic">Central African Republic</option>
                                                            <option style={{ color: 'black' }} value="Chad">Chad</option>
                                                            <option style={{ color: 'black' }} value="Chile">Chile</option>
                                                            <option style={{ color: 'black' }} value="China">China</option>
                                                            <option style={{ color: 'black' }} value="Christmas Island">Christmas Island</option>
                                                            <option style={{ color: 'black' }} value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                                                            <option style={{ color: 'black' }} value="Colombia">Colombia</option>
                                                            <option style={{ color: 'black' }} value="Comoros">Comoros</option>
                                                            <option style={{ color: 'black' }} value="Congo">Congo</option>
                                                            <option style={{ color: 'black' }} value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                                                            <option style={{ color: 'black' }} value="Cook Islands">Cook Islands</option>
                                                            <option style={{ color: 'black' }} value="Costa Rica">Costa Rica</option>
                                                            <option style={{ color: 'black' }} value="Cote D'ivoire">Cote D'ivoire</option>
                                                            <option style={{ color: 'black' }} value="Croatia">Croatia</option>
                                                            <option style={{ color: 'black' }} value="Cuba">Cuba</option>
                                                            <option style={{ color: 'black' }} value="Cyprus">Cyprus</option>
                                                            <option style={{ color: 'black' }} value="Czech Republic">Czech Republic</option>
                                                            <option style={{ color: 'black' }} value="Denmark">Denmark</option>
                                                            <option style={{ color: 'black' }} value="Djibouti">Djibouti</option>
                                                            <option style={{ color: 'black' }} value="Dominica">Dominica</option>
                                                            <option style={{ color: 'black' }} value="Dominican Republic">Dominican Republic</option>
                                                            <option style={{ color: 'black' }} value="Ecuador">Ecuador</option>
                                                            <option style={{ color: 'black' }} value="Egypt">Egypt</option>
                                                            <option style={{ color: 'black' }} value="El Salvador">El Salvador</option>
                                                            <option style={{ color: 'black' }} value="Equatorial Guinea">Equatorial Guinea</option>
                                                            <option style={{ color: 'black' }} value="Eritrea">Eritrea</option>
                                                            <option style={{ color: 'black' }} value="Estonia">Estonia</option>
                                                            <option style={{ color: 'black' }} value="Ethiopia">Ethiopia</option>
                                                            <option style={{ color: 'black' }} value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                                                            <option style={{ color: 'black' }} value="Faroe Islands">Faroe Islands</option>
                                                            <option style={{ color: 'black' }} value="Fiji">Fiji</option>
                                                            <option style={{ color: 'black' }} value="Finland">Finland</option>
                                                            <option style={{ color: 'black' }} value="France">France</option>
                                                            <option style={{ color: 'black' }} value="French Guiana">French Guiana</option>
                                                            <option style={{ color: 'black' }} value="French Polynesia">French Polynesia</option>
                                                            <option style={{ color: 'black' }} value="French Southern Territories">French Southern Territories</option>
                                                            <option style={{ color: 'black' }} value="Gabon">Gabon</option>
                                                            <option style={{ color: 'black' }} value="Gambia">Gambia</option>
                                                            <option style={{ color: 'black' }} value="Georgia">Georgia</option>
                                                            <option style={{ color: 'black' }} value="Germany">Germany</option>
                                                            <option style={{ color: 'black' }} value="Ghana">Ghana</option>
                                                            <option style={{ color: 'black' }} value="Gibraltar">Gibraltar</option>
                                                            <option style={{ color: 'black' }} value="Greece">Greece</option>
                                                            <option style={{ color: 'black' }} value="Greenland">Greenland</option>
                                                            <option style={{ color: 'black' }} value="Grenada">Grenada</option>
                                                            <option style={{ color: 'black' }} value="Guadeloupe">Guadeloupe</option>
                                                            <option style={{ color: 'black' }} value="Guam">Guam</option>
                                                            <option style={{ color: 'black' }} value="Guatemala">Guatemala</option>
                                                            <option style={{ color: 'black' }} value="Guernsey">Guernsey</option>
                                                            <option style={{ color: 'black' }} value="Guinea">Guinea</option>
                                                            <option style={{ color: 'black' }} value="Guinea-bissau">Guinea-bissau</option>
                                                            <option style={{ color: 'black' }} value="Guyana">Guyana</option>
                                                            <option style={{ color: 'black' }} value="Haiti">Haiti</option>
                                                            <option style={{ color: 'black' }} value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                                                            <option style={{ color: 'black' }} value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                                                            <option style={{ color: 'black' }} value="Honduras">Honduras</option>
                                                            <option style={{ color: 'black' }} value="Hong Kong">Hong Kong</option>
                                                            <option style={{ color: 'black' }} value="Hungary">Hungary</option>
                                                            <option style={{ color: 'black' }} value="Iceland">Iceland</option>
                                                            <option style={{ color: 'black' }} value="India">India</option>
                                                            <option style={{ color: 'black' }} value="Indonesia">Indonesia</option>
                                                            <option style={{ color: 'black' }} value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                                                            <option style={{ color: 'black' }} value="Iraq">Iraq</option>
                                                            <option style={{ color: 'black' }} value="Ireland">Ireland</option>
                                                            <option style={{ color: 'black' }} value="Isle of Man">Isle of Man</option>
                                                            <option style={{ color: 'black' }} value="Israel">Israel</option>
                                                            <option style={{ color: 'black' }} value="Italy">Italy</option>
                                                            <option style={{ color: 'black' }} value="Jamaica">Jamaica</option>
                                                            <option style={{ color: 'black' }} value="Japan">Japan</option>
                                                            <option style={{ color: 'black' }} value="Jersey">Jersey</option>
                                                            <option style={{ color: 'black' }} value="Jordan">Jordan</option>
                                                            <option style={{ color: 'black' }} value="Kazakhstan">Kazakhstan</option>
                                                            <option style={{ color: 'black' }} value="Kenya">Kenya</option>
                                                            <option style={{ color: 'black' }} value="Kiribati">Kiribati</option>
                                                            <option style={{ color: 'black' }} value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                                                            <option style={{ color: 'black' }} value="Korea, Republic of">Korea, Republic of</option>
                                                            <option style={{ color: 'black' }} value="Kuwait">Kuwait</option>
                                                            <option style={{ color: 'black' }} value="Kyrgyzstan">Kyrgyzstan</option>
                                                            <option style={{ color: 'black' }} value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                                                            <option style={{ color: 'black' }} value="Latvia">Latvia</option>
                                                            <option style={{ color: 'black' }} value="Lebanon">Lebanon</option>
                                                            <option style={{ color: 'black' }} value="Lesotho">Lesotho</option>
                                                            <option style={{ color: 'black' }} value="Liberia">Liberia</option>
                                                            <option style={{ color: 'black' }} value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                            <option style={{ color: 'black' }} value="Liechtenstein">Liechtenstein</option>
                                                            <option style={{ color: 'black' }} value="Lithuania">Lithuania</option>
                                                            <option style={{ color: 'black' }} value="Luxembourg">Luxembourg</option>
                                                            <option style={{ color: 'black' }} value="Macao">Macao</option>
                                                            <option style={{ color: 'black' }} value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                                                            <option style={{ color: 'black' }} value="Madagascar">Madagascar</option>
                                                            <option style={{ color: 'black' }} value="Malawi">Malawi</option>
                                                            <option style={{ color: 'black' }} value="Malaysia">Malaysia</option>
                                                            <option style={{ color: 'black' }} value="Maldives">Maldives</option>
                                                            <option style={{ color: 'black' }} value="Mali">Mali</option>
                                                            <option style={{ color: 'black' }} value="Malta">Malta</option>
                                                            <option style={{ color: 'black' }} value="Marshall Islands">Marshall Islands</option>
                                                            <option style={{ color: 'black' }} value="Martinique">Martinique</option>
                                                            <option style={{ color: 'black' }} value="Mauritania">Mauritania</option>
                                                            <option style={{ color: 'black' }} value="Mauritius">Mauritius</option>
                                                            <option style={{ color: 'black' }} value="Mayotte">Mayotte</option>
                                                            <option style={{ color: 'black' }} value="Mexico">Mexico</option>
                                                            <option style={{ color: 'black' }} value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                                                            <option style={{ color: 'black' }} value="Moldova, Republic of">Moldova, Republic of</option>
                                                            <option style={{ color: 'black' }} value="Monaco">Monaco</option>
                                                            <option style={{ color: 'black' }} value="Mongolia">Mongolia</option>
                                                            <option style={{ color: 'black' }} value="Montenegro">Montenegro</option>
                                                            <option style={{ color: 'black' }} value="Montserrat">Montserrat</option>
                                                            <option style={{ color: 'black' }} value="Morocco">Morocco</option>
                                                            <option style={{ color: 'black' }} value="Mozambique">Mozambique</option>
                                                            <option style={{ color: 'black' }} value="Myanmar">Myanmar</option>
                                                            <option style={{ color: 'black' }} value="Namibia">Namibia</option>
                                                            <option style={{ color: 'black' }} value="Nauru">Nauru</option>
                                                            <option style={{ color: 'black' }} value="Nepal">Nepal</option>
                                                            <option style={{ color: 'black' }} value="Netherlands">Netherlands</option>
                                                            <option style={{ color: 'black' }} value="Netherlands Antilles">Netherlands Antilles</option>
                                                            <option style={{ color: 'black' }} value="New Caledonia">New Caledonia</option>
                                                            <option style={{ color: 'black' }} value="New Zealand">New Zealand</option>
                                                            <option style={{ color: 'black' }} value="Nicaragua">Nicaragua</option>
                                                            <option style={{ color: 'black' }} value="Niger">Niger</option>
                                                            <option style={{ color: 'black' }} value="Nigeria">Nigeria</option>
                                                            <option style={{ color: 'black' }} value="Niue">Niue</option>
                                                            <option style={{ color: 'black' }} value="Norfolk Island">Norfolk Island</option>
                                                            <option style={{ color: 'black' }} value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                            <option style={{ color: 'black' }} value="Norway">Norway</option>
                                                            <option style={{ color: 'black' }} value="Oman">Oman</option>
                                                            <option style={{ color: 'black' }} value="Pakistan">Pakistan</option>
                                                            <option style={{ color: 'black' }} value="Palau">Palau</option>
                                                            <option style={{ color: 'black' }} value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                                                            <option style={{ color: 'black' }} value="Panama">Panama</option>
                                                            <option style={{ color: 'black' }} value="Papua New Guinea">Papua New Guinea</option>
                                                            <option style={{ color: 'black' }} value="Paraguay">Paraguay</option>
                                                            <option style={{ color: 'black' }} value="Peru">Peru</option>
                                                            <option style={{ color: 'black' }} value="Philippines">Philippines</option>
                                                            <option style={{ color: 'black' }} value="Pitcairn">Pitcairn</option>
                                                            <option style={{ color: 'black' }} value="Poland">Poland</option>
                                                            <option style={{ color: 'black' }} value="Portugal">Portugal</option>
                                                            <option style={{ color: 'black' }} value="Puerto Rico">Puerto Rico</option>
                                                            <option style={{ color: 'black' }} value="Qatar">Qatar</option>
                                                            <option style={{ color: 'black' }} value="Reunion">Reunion</option>
                                                            <option style={{ color: 'black' }} value="Romania">Romania</option>
                                                            <option style={{ color: 'black' }} value="Russian Federation">Russian Federation</option>
                                                            <option style={{ color: 'black' }} value="Rwanda">Rwanda</option>
                                                            <option style={{ color: 'black' }} value="Saint Helena">Saint Helena</option>
                                                            <option style={{ color: 'black' }} value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                                            <option style={{ color: 'black' }} value="Saint Lucia">Saint Lucia</option>
                                                            <option style={{ color: 'black' }} value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                                                            <option style={{ color: 'black' }} value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                                                            <option style={{ color: 'black' }} value="Samoa">Samoa</option>
                                                            <option style={{ color: 'black' }} value="San Marino">San Marino</option>
                                                            <option style={{ color: 'black' }} value="Sao Tome and Principe">Sao Tome and Principe</option>
                                                            <option style={{ color: 'black' }} value="Saudi Arabia">Saudi Arabia</option>
                                                            <option style={{ color: 'black' }} value="Senegal">Senegal</option>
                                                            <option style={{ color: 'black' }} value="Serbia">Serbia</option>
                                                            <option style={{ color: 'black' }} value="Seychelles">Seychelles</option>
                                                            <option style={{ color: 'black' }} value="Sierra Leone">Sierra Leone</option>
                                                            <option style={{ color: 'black' }} value="Singapore">Singapore</option>
                                                            <option style={{ color: 'black' }} value="Slovakia">Slovakia</option>
                                                            <option style={{ color: 'black' }} value="Slovenia">Slovenia</option>
                                                            <option style={{ color: 'black' }} value="Solomon Islands">Solomon Islands</option>
                                                            <option style={{ color: 'black' }} value="Somalia">Somalia</option>
                                                            <option style={{ color: 'black' }} value="South Africa">South Africa</option>
                                                            <option style={{ color: 'black' }} value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                                                            <option style={{ color: 'black' }} value="Spain">Spain</option>
                                                            <option style={{ color: 'black' }} value="Sri Lanka">Sri Lanka</option>
                                                            <option style={{ color: 'black' }} value="Sudan">Sudan</option>
                                                            <option style={{ color: 'black' }} value="Suriname">Suriname</option>
                                                            <option style={{ color: 'black' }} value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                                                            <option style={{ color: 'black' }} value="Swaziland">Swaziland</option>
                                                            <option style={{ color: 'black' }} value="Sweden">Sweden</option>
                                                            <option style={{ color: 'black' }} value="Switzerland">Switzerland</option>
                                                            <option style={{ color: 'black' }} value="Syrian Arab Republic">Syrian Arab Republic</option>
                                                            <option style={{ color: 'black' }} value="Taiwan, Province of China">Taiwan, Province of China</option>
                                                            <option style={{ color: 'black' }} value="Tajikistan">Tajikistan</option>
                                                            <option style={{ color: 'black' }} value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                                                            <option style={{ color: 'black' }} value="Thailand">Thailand</option>
                                                            <option style={{ color: 'black' }} value="Timor-leste">Timor-leste</option>
                                                            <option style={{ color: 'black' }} value="Togo">Togo</option>
                                                            <option style={{ color: 'black' }} value="Tokelau">Tokelau</option>
                                                            <option style={{ color: 'black' }} value="Tonga">Tonga</option>
                                                            <option style={{ color: 'black' }} value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                            <option style={{ color: 'black' }} value="Tunisia">Tunisia</option>
                                                            <option style={{ color: 'black' }} value="Turkey">Turkey</option>
                                                            <option style={{ color: 'black' }} value="Turkmenistan">Turkmenistan</option>
                                                            <option style={{ color: 'black' }} value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                                                            <option style={{ color: 'black' }} value="Tuvalu">Tuvalu</option>
                                                            <option style={{ color: 'black' }} value="Uganda">Uganda</option>
                                                            <option style={{ color: 'black' }} value="Ukraine">Ukraine</option>
                                                            <option style={{ color: 'black' }} value="United Arab Emirates">United Arab Emirates</option>
                                                            <option style={{ color: 'black' }} value="United Kingdom">United Kingdom</option>
                                                            <option style={{ color: 'black' }} value="United States">United States</option>
                                                            <option style={{ color: 'black' }} value="Uruguay">Uruguay</option>
                                                            <option style={{ color: 'black' }} value="Uzbekistan">Uzbekistan</option>
                                                            <option style={{ color: 'black' }} value="Vanuatu">Vanuatu</option>
                                                            <option style={{ color: 'black' }} value="Vatican City State (Holy See)">Vatican City State (Holy See)</option>
                                                            <option style={{ color: 'black' }} value="Venezuela">Venezuela</option>
                                                            <option style={{ color: 'black' }} value="Viet Nam">Viet Nam</option>
                                                            <option style={{ color: 'black' }} value="Virgin Islands, British">Virgin Islands, British</option>
                                                            <option style={{ color: 'black' }} value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                                                            <option style={{ color: 'black' }} value="Wallis and Futuna">Wallis and Futuna</option>
                                                            <option style={{ color: 'black' }} value="Western Sahara">Western Sahara</option>
                                                            <option style={{ color: 'black' }} value="Yemen">Yemen</option>
                                                            <option style={{ color: 'black' }} value="Zambia">Zambia</option>
                                                            <option style={{ color: 'black' }} value="Zimbabwe">Zimbabwe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="services">Service</label>
                                                        <select id="services" name="service" required style={{ width: '100%', background: 'rgba(255, 255, 255, .04)', border: '1px solid rgba(255, 255, 255, .05)', borderRadius: '5px', color: '#dedada', padding: '10px 15px' }}>
                                                            <option style={{ color: 'black' }} value="">------Select Service------</option>
                                                            <option style={{ color: 'black' }} value="Web Design & Development">Web Design & Development</option>
                                                            <option style={{ color: 'black' }} value="Mobile Website">Mobile Website</option>
                                                            <option style={{ color: 'black' }} value="E-Commerce">E-Commerce</option>
                                                            <option style={{ color: 'black' }} value="Logo Design">Logo Design</option>
                                                            <option style={{ color: 'black' }} value="App Development">App Development</option>
                                                            <option style={{ color: 'black' }} value="Print Design">Print Design</option>
                                                            <option style={{ color: 'black' }} value="Animated Videos">Animated Videos</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 mb-20">
                                                    <div className="form-inner">
                                                        <label htmlFor="message">Message</label>
                                                        <textarea id="message" name="message"></textarea>
                                                    </div>
                                                </div>
                                                {formSubmitted === true && (
                                                    <div className="confirmation-message success">
                                                        <p className='contact-para'>Thank you! Your form has been submitted successfully.</p>
                                                    </div>
                                                )}
                                                {errorMessage && (
                                                    <div className="confirmation-message error">
                                                        <p className='contact-para1'>{errorMessage}</p>
                                                    </div>
                                                )}
                                                <div className="col-lg-12">
                                                    <div className="form-inner">
                                                        <button className="primary-btn3" type="submit">
                                                            Submit
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </>

    );
};

export default Contact;
