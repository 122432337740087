import React, { useState } from "react";
import { Link } from "react-router-dom";
import 'animate.css';

import banner1 from '../assets/img/inner-pages/inner-banner-1.png';
import banner2 from '../assets/img/inner-pages/inner-banner-2.png';
import arrowIcon from '../assets/img/inner-pages/breadcrumb-arrow.svg';
import checkIcon from '../assets/img/home-4/check.svg';
import crossIcon from '../assets/img/home-4/cross.svg';

const Pricing = () => {
    const [activeTab, setActiveTab] = useState("logo");

    return (
        <>
            <section className="breadcrumbs">
                <div className="breadcrumb-sm-images">
                    <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                        <img src={banner1} alt="Banner 1" />
                    </div>
                    <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                        <img src={banner2} alt="Banner 2" />
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb-cnt">
                                    <span>Pricing</span>
                                    <h1 className="animate__animated animate__slideInUp" >Our Pricing Packages</h1>
                                    <div className="breadcrumb-list">
                                        <Link to="/">Home</Link>
                                        <img src={arrowIcon} alt="Breadcrumb Arrow" />
                                        <span>Pricing</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className="home4-pricing-plan">
                <div className="container-fluid">
                    <div
                        className="row wow">
                        <div className="col-lg-12">
                            <div className="section-title-4 two text-center">
                                <h2>Choose Your Package</h2>
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        <button
                                            className={`nav-link ${activeTab === "logo" ? "active" : ""}`}
                                            onClick={() => setActiveTab("logo")}
                                            type="button"
                                            role="tab"
                                            aria-selected={activeTab === "logo"}
                                        >
                                            Logo Package
                                        </button>
                                        <button
                                            className={`nav-link ${activeTab === "web" ? "active" : ""}`}
                                            onClick={() => setActiveTab("web")}
                                            type="button"
                                            role="tab"
                                            aria-selected={activeTab === "web"}
                                        >
                                            Web Package
                                        </button>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="tab-content animate__animated animate__zoomIn" id="nav-tabContent">
                        {activeTab === "logo" && (<div id="nav-home">
                            <div
                                class="tab-pane fade show active"
                                id="nav-home"
                                role="tabpanel"
                                aria-labelledby="nav-home-tab"
                                tabindex="0"
                            ></div>

                            <div class="row g-4 align-items-center justify-content-center">
                                <div
                                    className="col-xl-4 col-md-6 wow">
                                    <div className="pricing-card">
                                        <div className="pricing-top">
                                            <span>BASIC
                                                LOGO PACKAGE</span>
                                            <h2>
                                                <sup>$</sup>19.99<sub>/month</sub>
                                            </h2>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    1 Original Logo Concepts
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    1 Dedicated Logo Designers
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    2 Revisions
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    100% Ownership Rights
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Value-Added Services
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    30-days Money Back Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    With Grey Scale Format Free JPEG, PSD, EPS, AI, PNG.
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    &nbsp;
                                                    <img src={crossIcon} alt="cross icon" />
                                                </li>
                                                <li>
                                                    &nbsp;
                                                    <img src={crossIcon} alt="cross icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-xl-4 col-md-6 wow">
                                    <div className="pricing-card two">
                                        <div className="pricing-top">
                                            <span>STARTUP LOGO PACKAGE</span>
                                            <h2>
                                                <sup>$</sup>49.99<sub>/month</sub>
                                            </h2>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    3 Original Logo Concepts
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    2 Dedicated Logo Designers
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Unlimited Revisions
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    With Grey Scale Format Free JPEG, PSD, EPS, AI, PNG.
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    24-48 Hours Turn Around Time
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Dedicated Account Manager
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    30-days Money Back Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    100% Ownership Rights
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Stationery Design
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="col-xl-4 col-md-6 wow">
                                    <div className="pricing-card">
                                        <div className="pricing-top">
                                            <span>PREMIUM LOGO PACKAGE</span>
                                            <div className="pay-btn">
                                                <a className="primary-btn3" href="contact">
                                                    Get A Quote
                                                </a>
                                            </div>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    5 Original Logo Concepts
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    3 Dedicated Logo Designers
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Unlimited Revisions
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    With Grey Scale Format Free JPEG, PSD, EPS, AI, PNG.
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    24-48 Hours Turn Around Time
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Dedicated Account Manager
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    24-48 Hours Turn Around Time
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Bi-Fold Brochure Design
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    30-days Money Back Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )}
                    </div>

                    {activeTab === "web" && (<div id="nav-profile">
                        <div
                            className="tab-pane fade show active animate__animated animate__zoomIn"
                            id="nav-profile"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                            tabIndex="0"
                        >
                            <div className="row g-4 align-items-center justify-content-center">

                                <div className="col-xl-4 col-md-6">
                                    <div className="pricing-card">
                                        <div className="pricing-top">
                                            <span>STARTUP WEB PACKAGE </span>
                                            <h2>
                                                <sup>$</sup>299.99<sub>/month</sub>
                                            </h2>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    Website Designing
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    5 Pages Custom Web Design
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Content Management System
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    5 Stock Photos + jQuery Slide
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    1 Year Free Hosting & Domain
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Quick Turn Around Time
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Dedicated Account Manager
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    100% Satisfaction Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    30-days Money Back Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>&nbsp;
                                                    <img src={crossIcon} alt="cross icon" />
                                                </li>
                                                <li>&nbsp;
                                                    <img src={crossIcon} alt="cross icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6">
                                    <div className="pricing-card two">
                                        <div className="pricing-top">
                                            <span>PROMO WEB PACKAGE</span>
                                            <h2>
                                                <sup>$</sup>499.99<sub>/month</sub>
                                            </h2>
                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    Unlimited Pages Website
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Mobile Responsive
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Standard security measures
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Content Management System
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    1 Year Free Hosting & Domain
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Search Engine Submission
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Value-Added Services
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Dedicated Account Manager
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Social Media Design
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Google + Profile Banner
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Complete Deployment
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xl-4 col-md-6">
                                    <div className="pricing-card">
                                        <div className="pricing-top">
                                            <span>ENTERPRISE PACKAGE</span>

                                            <div className="pay-btn">
                                                <a className="primary-btn3" href="contact">
                                                    Get A Quote
                                                </a>
                                            </div>
                                            {/* <sup>$</sup>799<sub>/month</sub> */}

                                        </div>
                                        <div className="pricing-content">
                                            <ul>
                                                <li>
                                                    E-Commerce Website
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Custom E-Store Design
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Content Management System
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Payment Gateway Integration
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Shopping Cart Integration
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Sales Inventory Reports
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    1 Year Free Hosting & Domain
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    3 Months After Sales Support
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Dedicated Account Manager
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    30-days Money Back Guarantee
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                                <li>
                                                    Complete Deployment
                                                    <img src={checkIcon} alt="check icon" />
                                                </li>
                                            </ul>
                                            {/* <div className="pay-btn">
                                                <a className="primary-btn3" href="#">
                                                    Pay Now
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default Pricing;

