import React from 'react';
import { Link } from 'react-router-dom';
import 'animate.css';

import banner1 from '../assets/img/inner-pages/inner-banner-1.png';
import banner2 from '../assets/img/inner-pages/inner-banner-2.png';
import arrowIcon from '../assets/img/inner-pages/breadcrumb-arrow.svg';

import customWebImage from '../assets/pics/custom-web.jpg';
import mobileAppImage from '../assets/pics/mobile-app.jpg';
import ecommerceimgImage from '../assets/pics/ecommerce.jpg';
import logoDesignimgImage from '../assets/pics/logoimg.jpg';
import appDevimgImage from '../assets/pics/appdevimg.jpg';
import printImage from '../assets/pics/print1.jpg';
import animationImage from '../assets/pics/animation.jpg';


const Service = () => {
    return (
        <>
            <div className="home-dark2 tt-magic-cursor">
                <div className="breadcrumbs">
                    <div className="breadcrumb-sm-images">
                    <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                            <img src={banner1} alt="Banner 1" />
                        </div>
                        <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                            <img src={banner2} alt="Banner 2" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-wrapper">
                                    <div className="breadcrumb-cnt">
                                        <span>Service</span>
                                        <h1 className="animate__animated animate__slideInDown">Our Service Spectrum</h1>
                                        <div className="breadcrumb-list">
                                            <Link to="/">Home</Link>
                                            <img src={arrowIcon} alt="Arrow" />
                                            <span>Service</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="home6-solution-section sec-mar">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="single-solution">
                                    <div className="background-img">
                                        <img src={customWebImage} alt="CustomWeb" />
                                    </div>
                                    <div className="sl">
                                        <h2>01</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-web" target="_blank" rel="noopener noreferrer">Web Design & Development</a></h3>
                                        <p>
                                            Our Web Design & Development service is your gateway to a captivating online presence. We blend
                                            creativity with technical expertise to craft websites that engage, inspire, and convert. We deliver web
                                            solutions tailored to your unique needs, ensuring your digital footprint leaves a lasting impress.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-web" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="single-solution">
                                    <div className="background-img">
                                        <img src={mobileAppImage} alt="MobileApp" />
                                    </div>
                                    <div className="sl">
                                        <h2>02</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-mobile" target="_blank" rel="noopener noreferrer">Mobile Website</a></h3>
                                        <p>
                                            Our Mobile Website service is designed to put your business at everyone's fingertips. We specialize in
                                            creating responsive, user-friendly websites optimized for mobile devices. With the help of our
                                            experience, your website will automatically adjust to various screen sizes and deliver a top-notch
                                            mobile and tablet user experience. Stay ahead in the mobile era with our tailored solutions.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-mobile" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-10">
                                <div className="single-solution">
                                    <div className="background-img">
                                        <img src={ecommerceimgImage} alt="Ecommerce" />
                                    </div>
                                    <div className="sl">
                                        <h2>03</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-ecommerce" target="_blank" rel="noopener noreferrer">E-Commerce</a></h3>
                                        <p>
                                            Our Ecommerce service is your ticket to a thriving online business. We specialize in creating secure,
                                            user-friendly, and visually appealing e-commerce platforms that increase revenue and client
                                            satisfaction. From robust product catalogs to seamless payment solutions, we empower you to expand your
                                            digital storefront and tap into the vast world of online retail. Helping you in expanding and succeeding
                                            in the digital market.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-ecommerce" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-10'>
                                <div className='single-solution'>
                                    <div className='background-img'>
                                        <img src={logoDesignimgImage} alt="LogoDesign" />
                                    </div>
                                    <div className='sl'>
                                        <h2>04</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-logo" target="_blank" rel="noopener noreferrer">Logo Design</a></h3>
                                        <p>
                                            Our Logo Design service is the key to forging a memorable brand identity. We specialize in creating
                                            unique and distinctive logos that visually represent your business's essence. With a keen eye for
                                            aesthetics and a deep understanding of your brand's values, we create logos that leave a lasting
                                            impression and set you apart in a competitive market. Elevate your brand with our exceptional logo
                                            design expertise.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-logo" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-10'>
                                <div className='single-solution'>
                                    <div className='background-img'>
                                        <img src={appDevimgImage} alt="App Development" />
                                    </div>
                                    <div className='sl'>
                                        <h2>05</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-App-Development" target="_blank" rel="noopener noreferrer">App Development</a></h3>
                                        <p>
                                            Our App Development service is your gateway to innovation and digital transformation. We specialize in
                                            creating custom mobile and web applications that address your unique business needs. With a focus on
                                            user-centric design and cutting-edge technology, we create apps that deliver seamless functionality and
                                            exceptional user experiences. Our expertise in app development can help you unlock the full potential of
                                            the digital landscape.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-App-Development" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-10'>
                                <div className='single-solution'>
                                    <div className='background-img'>
                                        <img src={printImage} alt="Print" />
                                    </div>
                                    <div className='sl'>
                                        <h2>06</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-print" target="_blank" rel="noopener noreferrer">Print Design</a></h3>
                                        <p>
                                            Our Print Design service brings your brand to life in the physical world. We specialize in creating
                                            eye-catching and professionally crafted print materials. From brochures to business cards, our designs
                                            are tailored to convey your message effectively and leave a lasting impression. Trust us to turn your
                                            ideas in captivating printed assets that stand out and leave a lasting impression.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-print" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-4 col-md-6 col-sm-10'>
                                <div className='single-solution'>
                                    <div className='background-img'>
                                        <img src={animationImage} alt="Animation" />
                                    </div>
                                    <div className='sl'>
                                        <h2>07</h2>
                                    </div>
                                    <div className="solution-content">
                                        <h3><a href="service-details-animated-videos" target="_blank" rel="noopener noreferrer">Animated Videos</a></h3>
                                        <p>
                                            Our Animated Video service brings stories to life through captivating visuals and creative storytelling.
                                            We specialize in creating high-quality animated videos that engage, inform, and entertain your audience.
                                            Our team combines artistic talent with technical know-how to produce videos that have a lasting
                                            impression. Elevate your content with our animated video solutions and stand out in the world of visual
                                            storytelling.
                                        </p>
                                    </div>
                                    <div className="solution-btn-icon">
                                        <div className="learn-btn">
                                            <a className="primary-btn9" href="service-details-animated-videos" target="_blank" rel="noopener noreferrer">
                                                <span>Learn More</span>
                                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8 0.5L15 7.5M15 7.5L8 13.5M15 7.5L0 7.5" />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Service;