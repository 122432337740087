import React from 'react';
import { Link } from 'react-router-dom';
// import 'animate.css';

import banner1 from '../../assets/img/inner-pages/inner-banner-1.png';
import banner2 from '../../assets/img/inner-pages/inner-banner-2.png';
import breadcrumbArrow from "../../assets/img/inner-pages/breadcrumb-arrow.svg";

import ecommerceViewImage from "../../assets/pics/ecommerce.jpg";
import WorkIcon01 from '../../assets/img/inner-pages/work-icon-01.svg';
import WorkIcon02 from '../../assets/img/inner-pages/work-icon-02.svg';
import WorkIcon03 from '../../assets/img/inner-pages/work-icon-03.svg';
import WorkIcon04 from '../../assets/img/inner-pages/work-icon-04.svg';
import WorkIcon05 from '../../assets/img/inner-pages/work-icon-05.svg';


const Ecommerce = () => {
    return (
        <>
            <div className="home-dark2 tt-magic-cursor">
                <div className="breadcrumbs">
                    <div className="breadcrumb-sm-images">
                        <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                            <img src={banner1} alt="Banner 1" />
                        </div>
                        <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                            <img src={banner2} alt="Banner 2" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-wrapper">
                                    <div className="breadcrumb-cnt">
                                        <span className="animate__animated animate__slideInDown">Service Details</span>
                                        <h1>E-Commerce</h1>
                                        <div className="breadcrumb-list">
                                            <Link to="/">Home</Link>
                                            <img src={breadcrumbArrow} alt="Breadcrumb Arrow" />
                                            <span>Service Details of E-Commerce</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="service-details">
                <div className="about-services sec-mar">
                    <div className="container">
                        <div className="row gy-5">
                            <div
                                className="col-lg-6 d-flex align-items-center wow animate fadeInLeft"
                                data-wow-delay="200ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="about-content">
                                    <div className="section-title-5">
                                        <h2 className='animate__animated animate__slideInRight'>
                                            E-Commerce
                                        </h2>
                                    </div>
                                    <p className='animate__animated animate__slideInLeft'>
                                        In a world where online shopping is not just a trend but a way of life, having a robust e-commerce presence
                                        is vital for businesses looking to thrive and expand their reach. Our expert team specializes in designing
                                        and developing user-friendly online stores that are visually appealing, easy to navigate, and optimized for
                                        conversions. We understand that your e-commerce website is your digital storefront, and we ensure it reflects
                                        your brand's identity and values. We integrate secure and reliable payment gateways so your customers can
                                        make safe and seamless transactions. Your e-commerce platform's security is our top priority. We can help you
                                        achieve online retail success. Contact us today to learn how our e-commerce solutions can elevate your brand
                                        in the digital marketplace.
                                    </p>
                                    <ul className="about-featue">
                                        <li>
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.376831 8.16821C-0.247095 8.54593 -0.0579659 9.49862 0.662688 9.60837C1.24211 9.69666 1.52052 10.3701 1.17304 10.8431C0.740845 11.4312 1.27942 12.2389 1.98713 12.0639C2.55609 11.9231 3.07065 12.4387 2.9302 13.0088C2.75556 13.718 3.56158 14.2577 4.14855 13.8246C4.62054 13.4764 5.29275 13.7554 5.38073 14.336C5.49024 15.0581 6.44099 15.2476 6.81798 14.6224C7.12107 14.1198 7.84864 14.1198 8.15171 14.6224C8.52867 15.2476 9.47943 15.0581 9.58896 14.336C9.67707 13.7554 10.3492 13.4764 10.8211 13.8246C11.4081 14.2577 12.2142 13.718 12.0395 13.0088C11.899 12.4387 12.4136 11.9231 12.9826 12.0639C13.6903 12.2389 14.2289 11.4312 13.7967 10.8431C13.4492 10.3701 13.7276 9.69653 14.307 9.60837C15.0276 9.49864 15.2168 8.54597 14.5929 8.16821C14.0912 7.86452 14.0912 7.13547 14.5929 6.83178C15.2168 6.45407 15.0277 5.50138 14.307 5.39162C13.7276 5.30334 13.4492 4.62989 13.7967 4.15695C14.2289 3.56879 13.6903 2.76112 12.9826 2.93613C12.4136 3.07687 11.8991 2.5613 12.0395 1.99115C12.2141 1.28199 11.4081 0.742345 10.8211 1.17541C10.3492 1.52356 9.67695 1.2446 9.58896 0.664029C9.47945 -0.0580599 8.5287 -0.247606 8.15171 0.377594C7.84863 0.880237 7.12106 0.880237 6.81798 0.377594C6.44103 -0.247596 5.49027 -0.0580833 5.38073 0.664029C5.29263 1.24462 4.62054 1.5236 4.14855 1.17541C3.56158 0.742345 2.75554 1.28201 2.9302 1.99115C3.07065 2.56126 2.55612 3.07686 1.98713 2.93613C1.2794 2.76113 0.740845 3.56879 1.17304 4.15695C1.52049 4.62989 1.24209 5.30346 0.662688 5.39162C-0.0579425 5.50136 -0.247105 6.45403 0.376831 6.83178C0.878459 7.13548 0.878459 7.86453 0.376831 8.16821Z"
                                                ></path>
                                            </svg>
                                            Effective Products Showcase
                                        </li>
                                        <li>
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.376831 8.16821C-0.247095 8.54593 -0.0579659 9.49862 0.662688 9.60837C1.24211 9.69666 1.52052 10.3701 1.17304 10.8431C0.740845 11.4312 1.27942 12.2389 1.98713 12.0639C2.55609 11.9231 3.07065 12.4387 2.9302 13.0088C2.75556 13.718 3.56158 14.2577 4.14855 13.8246C4.62054 13.4764 5.29275 13.7554 5.38073 14.336C5.49024 15.0581 6.44099 15.2476 6.81798 14.6224C7.12107 14.1198 7.84864 14.1198 8.15171 14.6224C8.52867 15.2476 9.47943 15.0581 9.58896 14.336C9.67707 13.7554 10.3492 13.4764 10.8211 13.8246C11.4081 14.2577 12.2142 13.718 12.0395 13.0088C11.899 12.4387 12.4136 11.9231 12.9826 12.0639C13.6903 12.2389 14.2289 11.4312 13.7967 10.8431C13.4492 10.3701 13.7276 9.69653 14.307 9.60837C15.0276 9.49864 15.2168 8.54597 14.5929 8.16821C14.0912 7.86452 14.0912 7.13547 14.5929 6.83178C15.2168 6.45407 15.0277 5.50138 14.307 5.39162C13.7276 5.30334 13.4492 4.62989 13.7967 4.15695C14.2289 3.56879 13.6903 2.76112 12.9826 2.93613C12.4136 3.07687 11.8991 2.5613 12.0395 1.99115C12.2141 1.28199 11.4081 0.742345 10.8211 1.17541C10.3492 1.52356 9.67695 1.2446 9.58896 0.664029C9.47945 -0.0580599 8.5287 -0.247606 8.15171 0.377594C7.84863 0.880237 7.12106 0.880237 6.81798 0.377594C6.44103 -0.247596 5.49027 -0.0580833 5.38073 0.664029C5.29263 1.24462 4.62054 1.5236 4.14855 1.17541C3.56158 0.742345 2.75554 1.28201 2.9302 1.99115C3.07065 2.56126 2.55612 3.07686 1.98713 2.93613C1.2794 2.76113 0.740845 3.56879 1.17304 4.15695C1.52049 4.62989 1.24209 5.30346 0.662688 5.39162C-0.0579425 5.50136 -0.247105 6.45403 0.376831 6.83178C0.878459 7.13548 0.878459 7.86453 0.376831 8.16821Z"
                                                ></path>
                                            </svg>
                                            Easy Payment Module
                                            Integration
                                        </li>
                                        <li>
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.376831 8.16821C-0.247095 8.54593 -0.0579659 9.49862 0.662688 9.60837C1.24211 9.69666 1.52052 10.3701 1.17304 10.8431C0.740845 11.4312 1.27942 12.2389 1.98713 12.0639C2.55609 11.9231 3.07065 12.4387 2.9302 13.0088C2.75556 13.718 3.56158 14.2577 4.14855 13.8246C4.62054 13.4764 5.29275 13.7554 5.38073 14.336C5.49024 15.0581 6.44099 15.2476 6.81798 14.6224C7.12107 14.1198 7.84864 14.1198 8.15171 14.6224C8.52867 15.2476 9.47943 15.0581 9.58896 14.336C9.67707 13.7554 10.3492 13.4764 10.8211 13.8246C11.4081 14.2577 12.2142 13.718 12.0395 13.0088C11.899 12.4387 12.4136 11.9231 12.9826 12.0639C13.6903 12.2389 14.2289 11.4312 13.7967 10.8431C13.4492 10.3701 13.7276 9.69653 14.307 9.60837C15.0276 9.49864 15.2168 8.54597 14.5929 8.16821C14.0912 7.86452 14.0912 7.13547 14.5929 6.83178C15.2168 6.45407 15.0277 5.50138 14.307 5.39162C13.7276 5.30334 13.4492 4.62989 13.7967 4.15695C14.2289 3.56879 13.6903 2.76112 12.9826 2.93613C12.4136 3.07687 11.8991 2.5613 12.0395 1.99115C12.2141 1.28199 11.4081 0.742345 10.8211 1.17541C10.3492 1.52356 9.67695 1.2446 9.58896 0.664029C9.47945 -0.0580599 8.5287 -0.247606 8.15171 0.377594C7.84863 0.880237 7.12106 0.880237 6.81798 0.377594C6.44103 -0.247596 5.49027 -0.0580833 5.38073 0.664029C5.29263 1.24462 4.62054 1.5236 4.14855 1.17541C3.56158 0.742345 2.75554 1.28201 2.9302 1.99115C3.07065 2.56126 2.55612 3.07686 1.98713 2.93613C1.2794 2.76113 0.740845 3.56879 1.17304 4.15695C1.52049 4.62989 1.24209 5.30346 0.662688 5.39162C-0.0579425 5.50136 -0.247105 6.45403 0.376831 6.83178C0.878459 7.13548 0.878459 7.86453 0.376831 8.16821Z"
                                                ></path>
                                            </svg>
                                            Effortless Shopping Cart
                                        </li>

                                        <li>
                                            <svg
                                                width="15"
                                                height="15"
                                                viewBox="0 0 15 15"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M0.376831 8.16821C-0.247095 8.54593 -0.0579659 9.49862 0.662688 9.60837C1.24211 9.69666 1.52052 10.3701 1.17304 10.8431C0.740845 11.4312 1.27942 12.2389 1.98713 12.0639C2.55609 11.9231 3.07065 12.4387 2.9302 13.0088C2.75556 13.718 3.56158 14.2577 4.14855 13.8246C4.62054 13.4764 5.29275 13.7554 5.38073 14.336C5.49024 15.0581 6.44099 15.2476 6.81798 14.6224C7.12107 14.1198 7.84864 14.1198 8.15171 14.6224C8.52867 15.2476 9.47943 15.0581 9.58896 14.336C9.67707 13.7554 10.3492 13.4764 10.8211 13.8246C11.4081 14.2577 12.2142 13.718 12.0395 13.0088C11.899 12.4387 12.4136 11.9231 12.9826 12.0639C13.6903 12.2389 14.2289 11.4312 13.7967 10.8431C13.4492 10.3701 13.7276 9.69653 14.307 9.60837C15.0276 9.49864 15.2168 8.54597 14.5929 8.16821C14.0912 7.86452 14.0912 7.13547 14.5929 6.83178C15.2168 6.45407 15.0277 5.50138 14.307 5.39162C13.7276 5.30334 13.4492 4.62989 13.7967 4.15695C14.2289 3.56879 13.6903 2.76112 12.9826 2.93613C12.4136 3.07687 11.8991 2.5613 12.0395 1.99115C12.2141 1.28199 11.4081 0.742345 10.8211 1.17541C10.3492 1.52356 9.67695 1.2446 9.58896 0.664029C9.47945 -0.0580599 8.5287 -0.247606 8.15171 0.377594C7.84863 0.880237 7.12106 0.880237 6.81798 0.377594C6.44103 -0.247596 5.49027 -0.0580833 5.38073 0.664029C5.29263 1.24462 4.62054 1.5236 4.14855 1.17541C3.56158 0.742345 2.75554 1.28201 2.9302 1.99115C3.07065 2.56126 2.55612 3.07686 1.98713 2.93613C1.2794 2.76113 0.740845 3.56879 1.17304 4.15695C1.52049 4.62989 1.24209 5.30346 0.662688 5.39162C-0.0579425 5.50136 -0.247105 6.45403 0.376831 6.83178C0.878459 7.13548 0.878459 7.86453 0.376831 8.16821Z"
                                                ></path>
                                            </svg>
                                            Sales & Inventory Reports

                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div
                                className="col-lg-6 wow animate fadeInRight"
                                data-wow-delay="300ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="service-img magnetic-item">
                                    <img className="img-fluid"
                                        src={ecommerceViewImage}
                                        alt="Mobile View"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="how-we-work-section sec-mar">
                <div className="container">
                    <div
                        className="row mb-60 wow animate fadeInUp"
                        data-wow-delay="200ms"
                        data-wow-duration="1500ms"
                    >
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2 className='wdd'>How We Work</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="work-item">
                    <div className="container-fluid">
                        <div className="row row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-5 justify-content-center">
                            <div
                                className="col magnetic-item wow animate fadeInUp"
                                data-wow-delay="200ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="single-work">
                                    <div className="work-icon">
                                        <img src={WorkIcon01} alt="Requirements Gathering" />
                                    </div>
                                    <div className="work-content">
                                        <h4>Requirements Gathering</h4>
                                        <p className='rg'>
                                            We begin by closely collaborating with you to understand
                                            your project's objectives, target audience, and unique
                                            requirements. This crucial step sets the foundation for
                                            the entire project.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col magnetic-item wow animate fadeInUp"
                                data-wow-delay="300ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="single-work">
                                    <div className="work-icon">
                                        <img src={WorkIcon02} alt="Analysis and Planning" />
                                    </div>
                                    <div className="work-content">
                                        <h4>Analysis And Planning</h4>
                                        <p className='ap'>
                                            After gathering requirements, we conduct in-depth analysis
                                            and strategic planning. This involves defining the project
                                            scope, creating a timeline, and allocating resources
                                            effectively to ensure a smooth workflow.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col magnetic-item wow animate fadeInUp"
                                data-wow-delay="400ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="single-work">
                                    <div className="work-icon">
                                        <img src={WorkIcon03} alt="Design & Development" />
                                    </div>
                                    <div className="work-content">
                                        <h4>Design & Development</h4>
                                        <p className='dd'>
                                            Our creative team springs into action, designing and
                                            developing your website or web application. We focus on
                                            user-centric design, responsive layouts, and seamless
                                            functionality, all while adhering to the latest industry
                                            standards and best practices.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col magnetic-item wow animate fadeInUp"
                                data-wow-delay="500ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="single-work">
                                    <div className="work-icon">
                                        <img src={WorkIcon04} alt="Testing Device & Release" />
                                    </div>
                                    <div className="work-content">
                                        <h4>Testing Device & Release</h4>
                                        <p className='tdr'>
                                            Rigorous testing is paramount to ensure your website
                                            performs flawlessly. We assess its functionality across
                                            various devices and browsers, fixing any issues that may
                                            arise. Once we're confident in its quality, we release the
                                            website to the world.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div
                                className="col magnetic-item wow animate fadeInUp"
                                data-wow-delay="600ms"
                                data-wow-duration="1500ms"
                            >
                                <div className="single-work">
                                    <div className="work-icon">
                                        <img src={WorkIcon05} alt="Maintenance and Support" />
                                    </div>
                                    <div className="work-content">
                                        <h4>Launch and Distribution</h4>
                                        <p className='ms'>
                                            Our commitment extends beyond the launch. We provide ongoing
                                            maintenance and support, ensuring your app stays up-to-date,
                                            secure, and optimized for evolving technologies.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home4-contact-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-wrapper text-center magnetic-item">
                                <h2 className="title">Work With Us</h2>
                                <h2 className="content">Let’s Talk</h2>
                                <div className="contact-btn">
                                    <Link className="magnetic-item" to="/contact" target="_blank">
                                        Contact With Us.
                                        <svg
                                            width="32"
                                            height="32"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Ecommerce;