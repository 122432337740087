import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import phoneIcon from '../assets/img/home-6/phone.svg';

const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => {
        setMenuOpen((prev) => !prev);
        setIsDropdownOpen(false);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useEffect(() => {
        setMenuOpen(false);
    }, [location]);

    useEffect(() => {
        const handleScroll = () => {
            const header = document.querySelector(".header-area2");
            if (window.scrollY > 50) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (

        <header className="header-area2 style-2 two">
            <div className="header-logo">
                <Link to="/">
                    <img alt="logo" className="logo" src={logo} />
                </Link>
            </div>
            <div className={`main-menu ${menuOpen ? 'show-menu' : ''}`}>
                <div className="mobile-logo-area d-lg-none d-flex justify-content-between align-items-center">
                    <div className="mobile-logo-wrap">
                        <Link to="/">
                            <img alt="logo" src={logo} />
                        </Link>
                    </div>
                </div>
                <ul className="menu-list">
                    <li>
                        <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : '')}>Home</NavLink>
                    </li>
                    <li>
                        <NavLink to="/about" className={({ isActive }) => (isActive ? 'active' : '')}>About</NavLink>
                    </li>
                    <li className="menu-item-has-children">
                        <NavLink to="/service" className="drop-down" activeClassName="active">Services</NavLink>
                        <i className="bi bi-plus dropdown-icon" onClick={toggleDropdown}></i>
                        <ul className={`sub-menu ${isDropdownOpen ? 'show' : ''}`}>
                            <li><NavLink to="/service-details-web" activeClassName="active">Web Design & Development</NavLink></li>
                            <li><NavLink to="/service-details-mobile" activeClassName="active">Mobile Website</NavLink></li>
                            <li><NavLink to="/service-details-ecommerce" activeClassName="active">E-Commerce</NavLink></li>
                            <li><NavLink to="/service-details-logo" activeClassName="active">Logo Design</NavLink></li>
                            <li><NavLink to="/service-details-app-development" activeClassName="active">App Development</NavLink></li>
                            <li><NavLink to="/service-details-print" activeClassName="active">Print Design</NavLink></li>
                            <li><NavLink to="/service-details-animated-videos" activeClassName="active">Animated Videos</NavLink></li>
                        </ul>
                    </li>
                    <li>
                        <NavLink to="/portfolio" className={({ isActive }) => (isActive ? 'active' : '')}>Portfolio</NavLink>
                    </li>
                    <li>
                        <NavLink to="/pricing" className={({ isActive }) => (isActive ? 'active' : '')}>Pricing</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className={({ isActive }) => (isActive ? 'active' : '')}>Contact</NavLink>
                    </li>
                </ul>

                <div className="d-lg-none d-block">
                    <form className="mobile-menu-form">
                        <div className="hotline pt-30">
                            <div className="hotline-icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.5488 16.106C20.0165 15.5518 19.3745 15.2554 18.694 15.2554C18.0191 15.2554 17.3716 15.5463 16.8173 16.1005L15.0833 17.8291C14.9406 17.7522 14.7979 17.6809 14.6608 17.6096C14.4632 17.5108 14.2766 17.4175 14.1175 17.3187C12.4932 16.2871 11.0171 14.9426 9.6013 13.2031C8.91536 12.3361 8.45441 11.6063 8.11968 10.8655C8.56965 10.4539 8.9867 10.0259 9.39277 9.61431C9.54642 9.46066 9.70007 9.30152 9.85372 9.14787C11.0061 7.9955 11.0061 6.50291 9.85372 5.35054L8.35564 3.85246C8.18553 3.68234 8.00993 3.50674 7.8453 3.33115C7.51606 2.99092 7.17034 2.63972 6.81366 2.31047C6.28137 1.78368 5.64483 1.50381 4.97535 1.50381C4.30588 1.50381 3.65836 1.78368 3.10961 2.31047C3.10412 2.31596 3.10412 2.31596 3.09864 2.32145L1.23289 4.20365C0.530497 4.90605 0.129911 5.7621 0.0421114 6.75533C-0.089588 8.35768 0.382335 9.85027 0.744508 10.827C1.63348 13.2251 2.96145 15.4475 4.94243 17.8291C7.34594 20.699 10.2378 22.9653 13.5413 24.5622C14.8034 25.1603 16.4881 25.8682 18.3703 25.9889C18.4855 25.9944 18.6062 25.9999 18.716 25.9999C19.9836 25.9999 21.0482 25.5445 21.8823 24.639C21.8878 24.628 21.8987 24.6226 21.9042 24.6116C22.1896 24.2659 22.5188 23.9531 22.8645 23.6184C23.1005 23.3934 23.3419 23.1574 23.5779 22.9105C24.1212 22.3453 24.4065 21.6868 24.4065 21.0118C24.4065 20.3314 24.1157 19.6783 23.5614 19.1296L20.5488 16.106Z" />
                                    <path d="M14.0512 6.18495C15.4889 6.4264 16.7949 7.10685 17.8375 8.14947C18.8802 9.19209 19.5551 10.4981 19.8021 11.9358C19.8624 12.298 20.1752 12.5504 20.5319 12.5504C20.5758 12.5504 20.6142 12.5449 20.6581 12.5395C21.0642 12.4736 21.3331 12.0895 21.2672 11.6834C20.9709 9.94387 20.1478 8.35799 18.8911 7.10136C17.6345 5.84473 16.0486 5.0216 14.3091 4.72528C13.903 4.65943 13.5244 4.92832 13.4531 5.3289C13.3817 5.72949 13.6451 6.1191 14.0512 6.18495Z" />
                                    <path d="M25.9707 11.4691C25.4823 8.60468 24.1324 5.99813 22.0581 3.92387C19.9838 1.8496 17.3773 0.49968 14.5128 0.011294C14.1122 -0.0600432 13.7336 0.214331 13.6623 0.614917C13.5964 1.02099 13.8653 1.39963 14.2714 1.47096C16.8285 1.90447 19.1607 3.11721 21.0155 4.96649C22.8702 6.82125 24.0775 9.15343 24.511 11.7106C24.5714 12.0728 24.8841 12.3252 25.2408 12.3252C25.2847 12.3252 25.3231 12.3197 25.367 12.3142C25.7676 12.2539 26.042 11.8697 25.9707 11.4691Z" />
                                </svg>
                            </div>
                            <div className="hotline-info">
                                <span>Call Us Now</span>
                                <h6><a href="tel:+18886786284">(+1) 888-678-6284</a></h6>
                            </div>
                        </div>
                        <div className="email pt-20 d-flex align-items-center">
                            <div className="email-icon">
                                <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_461_205)">
                                        <path
                                            d="M23.5117 3.30075H2.38674C1.04261 3.30075 -0.0507812 4.39414 -0.0507812 5.73827V20.3633C-0.0507812 21.7074 1.04261 22.8008 2.38674 22.8008H23.5117C24.8558 22.8008 25.9492 21.7074 25.9492 20.3633V5.73827C25.9492 4.39414 24.8558 3.30075 23.5117 3.30075ZM23.5117 4.92574C23.6221 4.92574 23.7271 4.94865 23.8231 4.98865L12.9492 14.4131L2.07526 4.98865C2.17127 4.9487 2.27629 4.92574 2.38668 4.92574H23.5117ZM23.5117 21.1757H2.38674C1.93844 21.1757 1.57421 20.8116 1.57421 20.3632V6.70547L12.4168 16.1024C12.57 16.2349 12.7596 16.3008 12.9492 16.3008C13.1388 16.3008 13.3285 16.2349 13.4816 16.1024L24.3242 6.70547V20.3633C24.3242 20.8116 23.96 21.1757 23.5117 21.1757Z">
                                        </path>
                                    </g>
                                </svg>
                            </div>
                            <div className="email-info">
                                <span>Email Now</span>
                                <h6><a href="mailto:info@workflowoptimization.us">info@workflowoptimization.us</a></h6>
                            </div>
                        </div>
                    </form>
                    <div className="header-btn5">
                        <a className="primary-btn3" href="contact">Get A Quote</a>
                    </div>
                </div>
            </div>

            <div className="nav-right d-flex justify-content-end align-items-center">
                <div className="header-contact d-xl-block d-none">
                    <span>
                        <img className='animate__animated animate__tada' style={{animationDuration: '5s' }} src={phoneIcon} alt="phone" />
                        For Client Support:
                    </span>
                    <h6>
                        <a href="Tel:+18886786284">(+1) 888-678-6284</a>
                    </h6>
                </div>
                <div className="header-btn d-sm-flex d-none">
                    <a href="contact">Get A Quote</a>
                </div>
                <div className="sidebar-button mobile-menu-btn" onClick={toggleMenu}>
                    <span className={menuOpen ? 'active' : ''}></span>
                </div>
            </div>
        </header>
    );
};

export default Header;