import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'animate.css';
import CountUp from 'react-countup';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper-bundle.css';
import 'swiper/css/a11y';
import 'animate.css';

import teamImg1 from '../assets/img/home-3/team-01.png';
import teamImg2 from '../assets/img/home-3/team-02.png';
import teamImg3 from '../assets/img/home-3/team-03.png';
import teamImg4 from '../assets/img/home-3/team-04.png';

import working from '../assets/img/working with passion.png';
import customers from '../assets/img/satisfied customers.png';
import project from '../assets/img/project completed.png';
import awards from '../assets/img/awards.png';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../assets/css/bootstrap.min.css';
import '../assets/css/bootstrap-icons.css';
import '../assets/css/swiper-bundle.min.css';
import '../assets/css/animate.min.css';
import '../assets/css/jquery.fancybox.min.css';
import '../assets/css/boxicons.min.css';
import '../assets/css/preloader.css';

import banner1 from '../assets/img/inner-pages/inner-banner-1.png';
import banner2 from '../assets/img/inner-pages/inner-banner-2.png';
import arrowIcon from '../assets/img/inner-pages/breadcrumb-arrow.svg';
import aboutImg1 from '../assets/img/home-3/home3-about-1.png';
import aboutImg2 from '../assets/img/home-3/home3-about-2.png';
import rotateText from '../assets/img/home-3/rotate-text.png';

import logo1 from '../assets/logo-design-slider/animal/logo1.jpg';
import logo2 from '../assets/logo-design-slider/animal/logo2.jpg';
import logo3 from '../assets/logo-design-slider/animal/logo3.jpg';
import logo4 from '../assets/logo-design-slider/animal/logo4.jpg';
import eduLogo1 from '../assets/logo-design-slider/education/logo1.jpg';
import eduLogo2 from '../assets/logo-design-slider/education/logo2.jpg';
import eduLogo3 from '../assets/logo-design-slider/education/logo3.jpg';
import eduLogo4 from '../assets/logo-design-slider/education/logo4.jpg';
import marketingLogo1 from '../assets/logo-design-slider/marketing/logo1.jpg';
import marketingLogo2 from '../assets/logo-design-slider/marketing/logo2.jpg';
import marketingLogo3 from '../assets/logo-design-slider/marketing/logo3.jpg';
import marketingLogo4 from '../assets/logo-design-slider/marketing/logo4.jpg';
import healthcareLogo1 from '../assets/logo-design-slider/health-care/logo1.jpg';
import healthcareLogo2 from '../assets/logo-design-slider/health-care/logo2.jpg';
import healthcareLogo3 from '../assets/logo-design-slider/health-care/logo3.jpg';
import healthcareLogo4 from '../assets/logo-design-slider/health-care/logo4.jpg';

import logo1Animal from '../assets/logo-design-slider/animal/logo1.jpg';
import logo2Animal from '../assets/logo-design-slider/animal/logo2.jpg';
import logo3Animal from '../assets/logo-design-slider/animal/logo3.jpg';
import logo4Animal from '../assets/logo-design-slider/animal/logo4.jpg';
import logo1Education from '../assets/logo-design-slider/education/logo1.jpg';
import logo2Education from '../assets/logo-design-slider/education/logo2.jpg';
import logo3Education from '../assets/logo-design-slider/education/logo3.jpg';
import logo4Education from '../assets/logo-design-slider/education/logo4.jpg';
import logo1Entertainment from '../assets/logo-design-slider/entertainment/logo1.jpg';
import logo2Entertainment from '../assets/logo-design-slider/entertainment/logo2.jpg';
import logo3Entertainment from '../assets/logo-design-slider/entertainment/logo3.jpg';
import logo4Entertainment from '../assets/logo-design-slider/entertainment/logo4.jpg';
import logo1Finance from '../assets/logo-design-slider/finance/logo1.jpg';
import logo2Finance from '../assets/logo-design-slider/finance/logo2.jpg';
import logo3Finance from '../assets/logo-design-slider/finance/logo3.jpg';
import logo4Finance from '../assets/logo-design-slider/finance/logo4.jpg';
import logo1HealthCare from '../assets/logo-design-slider/health-care/logo1.jpg';
import logo2Food from '../assets/logo-design-slider/food/logo2.jpg';
import logo3Food from '../assets/logo-design-slider/food/logo3.jpg';
import logo4Food from '../assets/logo-design-slider/food/logo4.jpg';
import logo1Marketing from '../assets/logo-design-slider/marketing/logo1.jpg';

const logos = [
    { src: logo1, alt: 'Animal Logo 1' },
    { src: logo2, alt: 'Animal Logo 2' },
    { src: logo3, alt: 'Animal Logo 3' },
    { src: logo4, alt: 'Animal Logo 4' },
    { src: eduLogo1, alt: 'Education Logo 1' },
    { src: eduLogo2, alt: 'Education Logo 2' },
    { src: eduLogo3, alt: 'Education Logo 3' },
    { src: eduLogo4, alt: 'Education Logo 4' },
    { src: marketingLogo1, alt: 'Marketing Logo 1' },
    { src: marketingLogo2, alt: 'Marketing Logo 2' },
    { src: marketingLogo3, alt: 'Marketing Logo 3' },
    { src: marketingLogo4, alt: 'Marketing Logo 4' },
    { src: healthcareLogo1, alt: 'Healthcare Logo 1' },
    { src: healthcareLogo2, alt: 'Healthcare Logo 2' },
    { src: healthcareLogo3, alt: 'Healthcare Logo 3' },
    { src: healthcareLogo4, alt: 'Healthcare Logo 4' },
];

const About = () => {

    console.log(Autoplay);

    return (
        <>
            <div className="home-dark2 tt-magic-cursor">
                <div className="breadcrumbs">
                    <div className="breadcrumb-sm-images">
                        <div className="inner-banner-1 magnetic-item animate__animated animate__slideInDown">
                            <img src={banner1} alt="Banner 1" />
                        </div>
                        <div className="inner-banner-2 magnetic-item animate__animated animate__slideInUp">
                            <img src={banner2} alt="Banner 2" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb-wrapper">
                                    <div className="breadcrumb-cnt">
                                        <span>About Us</span>
                                        <h1 className="animate__animated animate__slideInUp">Pioneering Excellence in the Digital Realm</h1>
                                        <div className="breadcrumb-list">
                                            <Link to="/">Home</Link>
                                            <img src={arrowIcon} alt="Arrow" />
                                            <span>About Us</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home3-about-section sec-mar">
                    <div className="container-fluid">
                        <div className="row g-lg-4 gy-5">
                            <div className="col-lg-9">
                                <div className="section-title-3">
                                    <h2>About Us</h2>
                                    <p className="animate__animated animate__fadeInUp">
                                        Welcome to WMO Web, your one-stop creative hub for all things digital and visual!
                                        We specialize in crafting stunning web designs, memorable logos, eye-catching print
                                        materials, cutting-edge app development, and captivating animated videos. Our team of
                                        experts is committed to helping you in creating a unique and compelling online presence,
                                        ensuring your brand stands out in today's competitive landscape. Explore our diverse range
                                        of services and let us transform your ideas into captivating digital experiences.
                                    </p>
                                </div>
                                <div className="about-left">
                                    <div className="about-img">
                                        <img className="img-fluid magnetic-item" src={aboutImg1} alt="About 1" />
                                    </div>
                                    <div className="about-content animate__animated animate__zoomIn">
                                        <h2 className='wow animate__animated animate__fadeInRight" data-wow-delay="0.7s'>Unlock the potential of your business.</h2>
                                        <p>
                                            We believe in delivering tailored solutions that are designed to address your unique
                                            requirements. We take the time to understand your business and provide personalized
                                            services that align with your goals.
                                        </p>
                                        <div className="devider"></div>
                                        <ul className="about-feature">
                                            <li>
                                                <h5>Customized Solutions</h5>
                                                <p>Services are professional offerings provided.</p>
                                            </li>
                                            <li>
                                                <h5>Quality Reliability</h5>
                                                <p>Our services are characterized by a high level of professionalism.</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="about-right">
                                    <div className="about-img">
                                        <img className="img-fluid magnetic-item" src={aboutImg2} alt="About 2" />
                                    </div>
                                    <div className="about-exprience d-flex align-items-center justify-content-center">
                                        <div className="exp-text">
                                            <img src={rotateText} alt="Rotate Text" />
                                        </div>
                                        <div className="years">
                                            <h2>5<br /><span>Years</span></h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const FeatureArea = () => {
    return (
        <>
            <div className="home4-feature-area">
                <div className="container-fluid">
                    <div className="row g-xl-4 gy-5">
                        <div className="col-xl-3">
                            <div className="section-title-4">
                                <h2>Our Stats</h2>
                                <p>
                                    A Tapestry of Diversity and Unity. From coast to coast, each state tells a unique story in
                                    the grand narrative of our nation.
                                </p>
                            </div>
                        </div>
                        <div className="col-xl-9 d-flex justify-content-end">
                            <div className="counter-area">
                                <div className="row g-lg-0 gy-5">
                                    <div className="col-lg-3 col-sm-6 d-flex justify-content-center wow">
                                        <div className="single-counter">
                                            <div className="icon">
                                                <img src={working} alt="Working Icon" width="60" height="60" />
                                                <div className="content">
                                                    <div className="number">
                                                        <h3 className="counter">
                                                            <CountUp end={5} duration={15} />
                                                        </h3>
                                                        <span>Years</span>
                                                    </div>
                                                    <p>Working With Passion</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="col-lg-3 col-sm-6 d-flex justify-content-center wow">
                                        <div className="single-counter">
                                            <div className="icon">
                                                <img src={customers} alt="Customers Icon" width="60" height="60" />
                                                <div className="content">
                                                    <div className="number">
                                                        <h3 className="counter"><CountUp end={1.5} decimals={1} duration={15} /></h3>
                                                        <span>k</span>
                                                        <span>Customer</span>
                                                    </div>
                                                    <p>Satisfied Customer</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="col-lg-3 col-sm-6 d-flex justify-content-center wow">
                                        <div className="single-counter">
                                            <div className="icon">
                                                <img src={project} alt="Projects Icon" width="60" height="60" />
                                                <div className="content">
                                                    <div className="number">
                                                        <h3 className="counter">
                                                            <CountUp end={800} duration={10} />
                                                        </h3>
                                                        <span>Project</span>
                                                    </div>
                                                    <p>We Have Completed</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="col-lg-3 col-sm-6 d-flex justify-content-center wow">
                                        <div className="single-counter">
                                            <div className="icon">
                                                <img src={awards} alt="Awards Icon" width="60" height="60" />
                                                <div className="content">
                                                    <div className="number">
                                                        <h3 className="counter">
                                                            <CountUp end={15} duration={15} />
                                                        </h3>
                                                        <span>Awards</span>
                                                    </div>
                                                    <p>Achievement For Services</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home4-trusted-client-area sec-mar">
                <p>We take care of more than 1.5k Trusted Allies</p>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="marque-wrap">
                            <div className="marquee_text3">
                                <div className="partner-logo1">
                                    <img src={logo1Animal} alt="Animal Logo 1" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo2Animal} alt="Animal Logo 2" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo3Animal} alt="Animal Logo 3" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo4Animal} alt="Animal Logo 4" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo1Education} alt="Education Logo 1" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo2Education} alt="Education Logo 2" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo3Education} alt="Education Logo 3" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo4Education} alt="Education Logo 4" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo1Entertainment} alt="Entertainment Logo 1" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo2Entertainment} alt="Entertainment Logo 2" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo3Entertainment} alt="Entertainment Logo 3" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo4Entertainment} alt="Entertainment Logo 4" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo1Finance} alt="Finance Logo 1" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo2Finance} alt="Finance Logo 2" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo3Finance} alt="Finance Logo 3" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo4Finance} alt="Finance Logo 4" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo1HealthCare} alt="Health Care Logo 1" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo2Food} alt="Food Logo 2" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo3Food} alt="Food Logo 3" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo4Food} alt="Food Logo 4" />
                                </div>
                                <div className="partner-logo1">
                                    <img src={logo1Marketing} alt="Marketing Logo 1" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* <div className="home3-team-area sec-mar">
                <div className="container">
                    <div className="row mb-55 wow">
                        <div className="col-lg-12 d-flex justify-content-center">
                            <div className="section-title-3 text-center">
                                <h2>Team Members</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <Swiper
                                modules={[Autoplay, Pagination]}
                                spaceBetween={30}
                                slidesPerView={1}
                                loop
                                autoplay={{ delay: 2000, disableOnInteraction: false }}
                                pagination={{
                                    el: '.swiper-pagination',
                                    clickable: true,
                                }}
                                className="home3-team-slider"

                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >

                                <SwiperSlide>
                                    <div className="single-team">
                                        <div className="team-img">
                                            <img className="img-fluid" src={teamImg1} alt="Mateo Daniel" />
                                            <div className="social-area">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/"><i className="bx bxl-facebook"></i></a></li>
                                                    <li><a href="https://twitter.com/"><i className="bx bxl-twitter"></i></a></li>
                                                    <li><a href="https://www.pinterest.com/"><i className="bx bxl-pinterest-alt"></i></a></li>
                                                    <li><a href="https://www.instagram.com/"><i className="bx bxl-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team-content">
                                            <h5>Mateo Daniel</h5>
                                            <span>Founder, CTO</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-team">
                                        <div className="team-img">
                                            <img className="img-fluid" src={teamImg2} alt="Carter Jayden" />
                                            <div className="social-area">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/"><i className="bx bxl-facebook"></i></a></li>
                                                    <li><a href="https://twitter.com/"><i className="bx bxl-twitter"></i></a></li>
                                                    <li><a href="https://www.pinterest.com/"><i className="bx bxl-pinterest-alt"></i></a></li>
                                                    <li><a href="https://www.instagram.com/"><i className="bx bxl-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team-content">
                                            <h5>Carter Jayden</h5>
                                            <span>Co-Founder, CEO</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-team">
                                        <div className="team-img">
                                            <img className="img-fluid" src={teamImg3} alt="Andrew Santiago" />
                                            <div className="social-area">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/"><i className="bx bxl-facebook"></i></a></li>
                                                    <li><a href="https://twitter.com/"><i className="bx bxl-twitter"></i></a></li>
                                                    <li><a href="https://www.pinterest.com/"><i className="bx bxl-pinterest-alt"></i></a></li>
                                                    <li><a href="https://www.instagram.com/"><i className="bx bxl-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team-content">
                                            <h5>Andrew Santiago</h5>
                                            <span>Head of HR & Manager</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="single-team">
                                        <div className="team-img">
                                            <img className="img-fluid" src={teamImg4} alt="George Lorenzo" />
                                            <div className="social-area">
                                                <ul>
                                                    <li><a href="https://www.facebook.com/"><i className="bx bxl-facebook"></i></a></li>
                                                    <li><a href="https://twitter.com/"><i className="bx bxl-twitter"></i></a></li>
                                                    <li><a href="https://www.pinterest.com/"><i className="bx bxl-pinterest-alt"></i></a></li>
                                                    <li><a href="https://www.instagram.com/"><i className="bx bxl-instagram"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="team-content">
                                            <h5>George Lorenzo</h5>
                                            <span>Software Engineer</span>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>
                    </div>
                    <div className="row pt-50">
                        <div className="col-lg-12 position-relative">
                            <div className="swiper-pagination22"></div>
                        </div>
                    </div>
                </div>
            </div> */}


            <div className="home3-contact-area sec-mar">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="contact-wrapper text-left">
                                <h2 className="marquee_text4">
                                    We take care of your IT, So You Can Focus on Your Business.
                                </h2>
                                <div
                                    className="contact-btn magnetic-item wow">
                                    <a href="contact" target="_blank" rel="noopener noreferrer">
                                        Contact With Us
                                        <svg width="32" height="32" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 1H12M12 1V13M12 1L0.5 12"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

const CounterItem = ({ number, label, description }) => {
    return (
        <div className="col-lg-3 col-sm-6 d-flex justify-content-center">
            <div className="single-counter">
                <div className="icon">
                    <svg width="50" height="57" viewBox="0 0 50 57" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="25.5" cy="33.5" r="23.5" fill="url(#paint0_linear_1505_1233)" />
                    </svg>
                </div>
                <div className="content">
                    <div className="number">
                        <h3 className="counter">{number}</h3>
                        <span>{label}</span>
                    </div>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

const HomePage = () => {
    return (
        <div>
            <About />
            <FeatureArea />
            <CounterItem />
        </div>
    );
};

export default HomePage;
