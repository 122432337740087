import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../assets/img/logo.png';
import icon from '../assets/img/icon.png';
import footerImg3 from '../assets/img/footer/footer-slider-img3.jpg';
import footerImg4 from '../assets/img/footer/footer-slider-img4.jpg';

const Footer = () => {
    return (
        <footer className='main-footer'>
            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-top-content">
                                <div className="footer-logo">
                                    <Link to="/">
                                        <img className="logo-footer" src={logo} alt="Logo" />
                                    </Link>
                                </div>
                                <div className="footer-contact">
                                    <div className="footer-content">
                                        <div className="footer-icon">
                                            <img className="img-fluid animate__animated animate__tada" style={{animationDelay: '10s', animationDuration: '3s' }} src={icon} alt="Icon" />
                                        </div>
                                        <span>Call Any Time</span>
                                        <h6>
                                            <a href="tel:+18886786284">(+1) 888-678-6284</a>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row g-lg-4 gy-5">
                    <div className="col-lg-4 col-sm-6 d-flex">
                        <div className="footer-widget">
                            <div className="footer-contact mb-40">
                                <h4>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M7.29163 14.6767V18.5417C7.29227 18.6731 7.33422 18.8009 7.41154 18.9071C7.48886 19.0133 7.59764 19.0924 7.72245 19.1334C7.84727 19.1743 7.9818 19.1749 8.10699 19.1351C8.23217 19.0954 8.34167 19.0172 8.41997 18.9117L10.6808 15.8351L7.29163 14.6767ZM19.7375 0.115895C19.6436 0.0490233 19.533 0.00942685 19.418 0.00148505C19.303 -0.00645676 19.188 0.0175656 19.0858 0.0708953L0.3358 9.86256C0.227918 9.91958 0.13903 10.0069 0.0800422 10.1137C0.0210548 10.2205 -0.00546466 10.3422 0.00373833 10.4639C0.0129413 10.5855 0.0574664 10.7019 0.131849 10.7986C0.206232 10.8953 0.307236 10.9683 0.422467 11.0084L5.63497 12.7901L16.7358 3.2984L8.1458 13.6476L16.8816 16.6334C16.9683 16.6625 17.0603 16.6723 17.1512 16.6622C17.2421 16.652 17.3296 16.6221 17.4078 16.5746C17.4859 16.527 17.5526 16.463 17.6034 16.3869C17.6542 16.3108 17.6877 16.2246 17.7016 16.1342L19.9933 0.717562C20.0103 0.603437 19.9953 0.486844 19.9502 0.380665C19.905 0.274486 19.8314 0.182855 19.7375 0.115895Z" />
                                    </svg>
                                    Say Hello
                                </h4>
                                <p>
                                    <a href="mailto:info@workflowoptimization.us">info@workflowoptimization.us</a>
                                </p>
                            </div>
                            <div className="footer-contact">
                                <h4>Work Flow Optimization Web</h4>
                                <p style={{ color: "white", textAlign: "justify" }}>
                                    Welcome to WMO Web, your one-stop creative hub for all things digital and visual! We specialize
                                    in crafting stunning web designs, memorable logos, eye-catching print materials, cutting-edge app development,
                                    and captivating animated videos.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex justify-content-lg-start justify-content-sm-end">
                        <div className="footer-widget">
                            <div className="widget-title">
                                <h4>What We Do</h4>
                            </div>
                            <div className="menu-container">
                                <ul>
                                    <li><Link to="/service-details-web">Web Design & Development</Link></li>
                                    <li><Link to="/service-details-Mobile">Mobile Website</Link></li>
                                    <li><Link to="/service-details-ecommerce">E-Commerce</Link></li>
                                    <li><Link to="/service-details-logo">Logo Design</Link></li>
                                    <li><Link to="/service-details-print">Print Design</Link></li>
                                    <li><Link to="/service-details-App-Development">App Development</Link></li>
                                    <li><Link to="/service-details-animated-videos">Animated Videos</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-6 d-flex justify-content-lg-center">
                        <div className="footer-widget">
                            <div className="widget-title">
                                <h4>Satisfaction & Guarantee</h4>
                            </div>
                            <div className="menu-container">
                                <div className="footer-colum slider-footer-col">
                                    <div className="footer-slider">
                                        <div>
                                            <img src={footerImg3} alt="marvel images" />
                                        </div>
                                        <div>
                                            <img src={footerImg4} alt="marvel images" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 d-flex justify-content-sm-end">
                        <div className="footer-widget">
                            <div className="widget-title">
                                <h4>Useful Links</h4>
                            </div>
                            <div className="menu-container">
                                <ul>

                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about">About</Link></li>
                                    <li><Link to="/service">Services</Link></li>
                                    <li><Link to="/portfolio">Portfolio</Link></li>
                                    <li><Link to="/pricing">Pricing</Link></li>
                                    <li><Link to="/contact">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-btm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-btn-content">
                                <div className="copyright-area">
                                    <p>
                                        ©Copyright 2023
                                        <a href="/"> Work Flow Optimization Web</a>
                                    </p>
                                </div>
                                <div className="footer-social">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="bx bxl-facebook"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="bx bxl-twitter"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                                                <i className="bx bxl-instagram"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
